import React, {Component} from 'react'
import styled from 'styled-components'
import { LazyLoad, ImageStyled } from './Common'
import ReactPlayer from 'react-player'

const Image = ({children, style, isSelected, ...props}) => {

  return (
    <ImageStyled {...props}>
      <LazyLoad hide={!props.nearViewport}>

        <img
          style={{objectFit:'contain', display:'block', width:'100%', maxWidth:'100%', maxHeight:'80vh', ...style}}
          src={props.value}/>

        {children}

      </LazyLoad>
    </ImageStyled>
  )
}


// const Video = ({}) =>
//


export { Image }
