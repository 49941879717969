import {
  SET_ACTIVE_CHAPTER,
  SELECT_BLOCK,
} from './actions'


export default function nav(state = {}, action) {
  switch (action.type) {
    case SET_ACTIVE_CHAPTER:
      return Object.assign({}, state, {activeChapter: action.chapterId})
    case SELECT_BLOCK:
      return Object.assign({}, state, {selectedBlock: action.blockId})
    default:
      return state
  }
}
