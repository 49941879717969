import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { Link, Redirect } from 'react-router-dom'

import styled from 'styled-components'

const Heading = styled.div((props) => ({
  fontSize: 20,
  fontWeight: 800,
  margin: 16,
  textAlign: 'center',
}))

const ButtonContainer = styled.div((props) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
}))

const Button = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 72,
  ':hover': {
    backgroundColor: props.color ? props.color : '#C54E35',
  },
}))

const StoryCard = ({
  story,
  handleClickOpen,
  handleClickDuplicate,
  handleClickDelete,
  i,
  ...props
}) => {
  const [isOver, setIsOver] = useState(false)

  return (
    <motion.li
      style={{
        y: 200,
        opacity: 0,
        scale: 0.9,
        margin: 8,
        width: 200,
        minWidth: 180,
        height: 355,
        cursor: 'pointer',
        backgroundColor: '#222',
        border: '1px solid #333',
        display: 'inline-block',
        padding: 32,
        borderRadius: 16,
        overflow: 'hidden',
      }}
      animate={{
        y: 0,
        opacity: 1,
        scale: 1,
        transition: {
          delay: 0.05 * i,
          y: { type: 'spring', stiffness: 50, velocity: 5 },
        },
      }}
      whileHover={{
        scale: 1.02,
        backgroundColor: '#333',
      }}
      whileTap={{ scale: 0.98, backgroundColor: '#5930E5' }}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      onClick={handleClickOpen.bind(null, story.id)}
    >
      <Heading>{story.title}</Heading>
      <ButtonContainer>
        {isOver && (
          <Button
            color={'#1205FF'}
            onClick={(event) => handleClickDuplicate(event, story.id)}
          >
            Duplicate
          </Button>
        )}
        {isOver && (
          <Button onClick={(event) => handleClickDelete(event, story.id)}>
            Delete
          </Button>
        )}
      </ButtonContainer>
    </motion.li>
  )
}

export default StoryCard
