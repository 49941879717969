import React from 'react';
import ReactDOM from 'react-dom';
import Router from './components';
// import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'

import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

import { createFirestoreInstance } from 'redux-firestore'

import createReduxStore from "./redux/createReduxStore"
import { firebase } from './config/constants'

const rrfConfig = { userProfile: 'users', useFirestoreForProfile: true } // react-redux-firebase config

const store = createReduxStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root'));
unregisterServiceWorker();
