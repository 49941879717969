import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { setActiveChapter, selectBlock } from '../../redux/actions'

import {
  DefaultChapter,
  CoverChapter,
  ImageFillChapter,
  ImageFixedChapter,
  Empty,
} from './ChapterTypes/'

import Button from '../Button'
import { ChapterDivider } from './ChapterDivider'

import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt'
import { Plus } from '@styled-icons/boxicons-regular/Plus'

const chapterTypes = {
  empty: Empty,
  FDsXOeGewMqIYqWH6e0S: DefaultChapter,
  zA9twpIHfM8M7LHSDs37: CoverChapter,
  TGKWpCXvgU7uY2Rzy6Eg: ImageFillChapter,
  L5FMfIQ64EvUlSV56CAe: ImageFixedChapter,
}

var getScroll = () => {
  return window.pageYOffset
}

var getWindowHeight = () => {
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  )
}

function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

class Chapter extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = { loaded: false, ref: null }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    // if(!this.props.activeChapter) this.props.setActiveChapter(this.props.id)
    if (this.props.chapterIndex === 0) {
      this.props.setActiveChapter(this.props.id)
      this.setState({ loaded: true, nearViewport: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.blocks !== nextProps.blocks) return true
    if (this.props.isActive !== nextProps.isActive) return true
    if (this.state.nearViewport !== nextState.nearViewport) return true
    if (this.props.typeId !== nextProps.typeId) return true
    if (this.props.order !== nextProps.order) return true
    if (this.state.loaded !== nextState.loaded) return true
    return false
  }

  // Track scroll, activate chapters and update active chapter
  // This is stupid. We're handling scroll in EVERY CHAPTER
  handleScroll = debounce(() => {
    // console.log('Tracking scroll in', this.props.chapterIndex);
    if (this.ref && this.ref.current) {
      const { offsetTop, clientHeight } = this.ref.current
      const scrollPosition = getScroll() + getWindowHeight() / 2

      // Set active chapter
      if (
        offsetTop < scrollPosition &&
        offsetTop + clientHeight > scrollPosition &&
        this.props.activeChapterId !== this.props.id
      ) {
        this.props.setActiveChapter(this.props.id)
      }

      // Load chapter
      if (!this.state.loaded && Math.abs(offsetTop - scrollPosition) < 1000) {
        this.setState({ loaded: true })
      }

      if (
        Math.abs(offsetTop - (scrollPosition - clientHeight / 2)) < clientHeight
      ) {
        this.setState({ nearViewport: true })
      } else if (this.state.nearViewport) {
        this.setState({ nearViewport: false })
      }
    }
  }, 15)

  render() {
    if (!this.props.blocks) return null
    if (!this.state.loaded)
      return <div ref={this.ref} style={{ height: '100vh' }} />

    const { blocks } = this.props

    // Organize blocks by block type
    var blocksByType = {}
    blocks
      .filter((block) => block)
      .forEach((block, index) => {
        blocksByType[block.type] = blocksByType[block.type] || []
        blocksByType[block.type].push({
          ...block,
          ...{ index: index, blockId: block.id },
        })
      })

    var typeId = this.props.typeId
    if (!blocks || blocks.length === 0 || !this.props.typeId) typeId = 'empty'

    // Chapter Layout = Chapter Type
    const SpecificChapter = chapterTypes[typeId]

    return (
      <div
        id={this.props.id}
        onClick={() => this.props.selectBlock(null)}
        ref={this.ref}
      >
        <Controls
          changeChapterType={this.props.changeChapterType}
          addChapter={this.props.addChapter}
          chapterIndex={this.props.chapterIndex}
          canEdit={this.props.canEdit}
        >
          <SpecificChapter
            blocksByType={blocksByType}
            isActive={this.props.isActive}
            nearViewport={this.state.nearViewport}
            {...this.props}
          />
        </Controls>
      </div>
    )
  }
}

const Controls = ({
  children,
  changeChapterType,
  addChapter,
  chapterIndex,
  canEdit,
}) => (
  <>
    <div style={{ display: 'flex', width: '100%' }}>
      {canEdit && (
        <ChapterDivider
          vertical
          placement={'left'}
          onClick={() => changeChapterType(-1)}
        >
          <Button size={40}>
            <LeftArrowAlt style={{ height: 32 }} />
          </Button>
        </ChapterDivider>
      )}
      {children}
      {canEdit && (
        <ChapterDivider
          vertical
          placement={'right'}
          onClick={() => changeChapterType(1)}
        >
          <Button size={40}>
            <RightArrowAlt style={{ height: 32 }} />
          </Button>
        </ChapterDivider>
      )}
    </div>
    {canEdit && (
      <ChapterDivider onClick={() => addChapter(chapterIndex)}>
        <Button size={20}>
          <Plus style={{ height: 32 }} />
        </Button>
      </ChapterDivider>
    )}
  </>
)

Chapter.propTypes = {
  typeId: PropTypes.string,
  contents: PropTypes.array.isRequired,
}

Chapter.defaultProps = {
  typeId: null,
  contents: [],
}

const mapStateToProps = (state, ownProps) => {
  return {
    story: state.firestore.activeStory,
    isActive: ownProps.id === state.nav.activeChapter,
    activeChapterId: state.nav.activeChapter,
    chaptersOrdered: state.firestore.ordered.chapters, // will this cause unnecessary renders?
    blocks: state.firestore.ordered['blocksByChapter.' + ownProps.id],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveChapter: (id) => {
      dispatch(setActiveChapter(id))
    },
    selectBlock: (id) => {
      dispatch(selectBlock(id))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'blocks',
        where: ['chapterId', '==', props.id],
        orderBy: 'order',
        storeAs: 'blocksByChapter' + '.' + props.id,
      },
    ]
  })
)(Chapter)
