import React, {Component} from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion"




const LazyLoad = ({children, ...props}) =>
  <motion.div
    style={{display: false && 'none', opacity:0}}
    animate={{opacity: props.hide ? 0 : 1}}
    transition={{duration:.5}}
    {...props}
  >
  {children}
  </motion.div>


// const LazyLoad = ({children, ...props}) => {
// return(
//   <div
//     style={{display: props.hide && 'none', opacity:1}}
//     {...props}
//   >
//   {children}
//   </div>
// )
// }


const WidthContraint = styled.div(props => ({
  width:'100%',
  maxWidth:'60ch',
  position:'relative',
  marginBottom:32,
  display:'flex',
  alignItems:'center',
  '@media (max-width: 1300px)': {
  },
  '@media (max-width: 800px)': {
  }
}))

const ImageStyled = styled.div(props => ({

  flex:'1 1 0',
  position:'relative',
  margin:4,
  maxWidth:props.narrow ? 600 : '100%',
  // background:props.selected && '#f00',
  ...props.style,
  '@media (max-width: 1100px)': {
  },
  '@media (max-width: 768px)': {
    // padding:'0 32px 0 32px'
  },
}))


export { LazyLoad, WidthContraint, ImageStyled }
