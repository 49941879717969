import React, {Component} from 'react'
import { connect } from 'react-redux'
import {
  // compose,
  withHandlers,
  withContext,
  getContext,
} from 'recompose'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import queryString from 'query-string'
import { withRouter } from 'react-router'
import { useSwipeable, Swipeable } from 'react-swipeable'
import styled from 'styled-components'
import {db, firebase} from '../../config/constants'
import { deleteBlock } from '../../helpers/firestoreActions'
import Chapter from './Chapter'
import ChapterList from './ChapterList'
import AddChapter from './AddChapter'
import ChapterControls from '../ChapterControls/ChapterControls'
import Loading from '../Loading'
import SwipingOverlay from './SwipingOverlay'

var getScroll = () => {
  return(window.pageYOffset)
}

class ChapterController extends Component {
  constructor(props) {
    super(props)
    this.state = {swipingTo:{chapterType:null, dir:''}}
  }

  componentDidMount() {

    if(this.props.canEdit) {
      document.addEventListener('input', e => {

        const blockId = e.target.id
        const newValue = e.target.innerText
        this.updateBlockValue(blockId, newValue)

      })

      document.addEventListener("paste", this.handlePaste)
      document.addEventListener("keydown", this.handleKeyDown)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.handlePaste)
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.chaptersOrdered !== this.props.chaptersOrdered) return true
    if(nextProps.chapterTypes !== this.props.chapterTypes) return true
    return false
  }

  handlePaste = (e) => {
    console.log(this.props.activeChapterId)
    if(this.canPaste()) {
      const text = e.clipboardData.getData('Text')
      var type = 'p'
      var value = text

      if(text.split(' ').length < 6) type = 'h1'
      if(text.indexOf('.gif') !== -1 || text.indexOf('.jpg') !== -1 || text.indexOf('.png') !== -1 || text.indexOf('.jpeg') !== -1) type = 'image'
      if(text.indexOf('open.spotify.com/') !== -1) type = 'spotify'
      if(text.indexOf('vimeo.com') !== -1) {
        var regex = /[0-9]+/g
        const match = text.match(regex)[0]
        if(match) {
          type = 'vimeo'
          value = match
        }
      }
      console.log(value)
      this.addBlock({
        type,
        value,
        chapterId: this.props.activeChapterId
      })
    }
  }

  handleKeyDown = (e) => {
    if(this.activeElementIsInput()) return
    if(e.altKey) {

      switch(e.code) {
        case 'KeyN':
          e.preventDefault()
          this.addChapter()
        break;
        case 'ArrowUp':
          e.preventDefault()
          this.moveChapterUp()
        break;
        case 'Backspace':
          e.preventDefault()
          this.deleteChapter()
        break;
      }
    }
    else {
      switch(e.code) {
        case 'ArrowRight':
          this.changeChapterType(1)
        break;
        case 'ArrowLeft':
          this.changeChapterType(-1)
        break;
        case 'Backspace':
          this.props.firestore.collection('blocks').doc(this.props.selectedBlock).delete()
          // deleteBlock(this.props.selectedBlock)
        break;
      }
    }

  }

  canPaste = () => {
    var activeElement = document.activeElement
    var inputs = ['input', 'select', 'button', 'textarea' ]

    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
      return false
    }
    return true
  }

  activeElementIsInput = () => {
    var activeElement = document.activeElement

    var inputs = ['input', 'textarea' ]

    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
      return true
    }
    return false
  }

  addChapter = (insertionPoint) => {

    console.log('adding')
    const {activeChapterId, chaptersOrdered} = this.props
    // alert(insertionPoint)
    // If no insertionPoint provided, get active chapter index
    var activeChapterIndex = null
    chaptersOrdered.forEach((chapter, i) => {
      if(chapter.id === activeChapterId) activeChapterIndex = i
    })
    if(insertionPoint === undefined) insertionPoint = activeChapterIndex

    const upperInsertionPointChapterOrder = (chaptersOrdered.length > insertionPoint) ? chaptersOrdered[insertionPoint].order : 0
    const lowerInsertionPointChapterOrder = chaptersOrdered.length > insertionPoint + 1 ? chaptersOrdered[insertionPoint+1].order : upperInsertionPointChapterOrder+2**20

    const newChapterOrder = (upperInsertionPointChapterOrder + lowerInsertionPointChapterOrder)/2

    const defaultChapterTypeId = this.props.defaultChapterTypeId
    const defaultChapterDefaults = this.props.chapterTypes[defaultChapterTypeId].defaults

    // console.log(upperInsertionPointChapterOrder, lowerInsertionPointChapterOrder)

    const newChapter = {
      ...defaultChapterDefaults,
      typeId: defaultChapterTypeId,
      storyId:this.props.storyId,
      order: newChapterOrder
    }

    this.props.firestore.add({ collection: 'chapters' }, newChapter)
      // .then(docRef => {
      //   const distance = activeChapterIndex >= insertionPoint ? window.innerHeight : -window.innerHeight
      //   window.scrollTo({
      //     top: getScroll() + distance,
      //     behavior: 'smooth'
      //   })
      // })
  }

  deleteChapter  = () => {
    const {activeChapterId} = this.props

    // Delete blocks first
    var batch = db.batch()
    db.collection('blocks').where('chapterId', '==', activeChapterId).get()
      .then(snap => {
        snap.forEach(doc =>  {
          batch.delete(doc.ref)
        })
        return batch.commit()
      })
      .then(() => {
        // All blocks were deleted, proceed to delete chapter
        this.props.firestore.delete({ collection: 'chapters', doc: activeChapterId })
      })
      .catch(e => {
        console.log('Oops, someone screwed up', e)
      })

  }

  moveChapterUp = () => {
    const {activeChapterId, chaptersOrdered} = this.props
    var activeChapterIndex = null
    chaptersOrdered.forEach((chapter, i) => {
      if(chapter.id === activeChapterId) activeChapterIndex = i
    })

    const destinationIndex = activeChapterIndex - 1 > 0 ? activeChapterIndex - 1 : 0
    this.moveChapterTo(destinationIndex)
  }

  moveChapterTo = (index) => {
    const {activeChapterId,  chaptersOrdered} = this.props

    const upperInsertionPointChapterOrder = index === 0 ? 0 : chaptersOrdered[index-1].order
    const lowerInsertionPointChapterOrder = index >= chaptersOrdered.length-1 ? chaptersOrdered[chaptersOrdered.length-1].order : chaptersOrdered[index].order

    const newChapterOrder = (upperInsertionPointChapterOrder + lowerInsertionPointChapterOrder)/2

    this.props.firestore.update({ collection: 'chapters', doc: activeChapterId }, {order:newChapterOrder})
  }

  changeChapterType = (increment) => {

    if(!increment || typeof increment !== 'number') increment = 1
    if(!this.props.availableChapterTypes || !this.props.activeChapter) return null

    // Get the active chapter type
    const activeChapter = this.props.activeChapter
    const activeChapterTypeId = activeChapter.typeId ? activeChapter.typeId : this.props.defaultChapterTypeId

    // Get the chapter types available in this story
    const availableChapterTypes = this.props.availableChapterTypes

    // Calculate index of new type within the available types
    const activeChapterTypeIndex = availableChapterTypes.indexOf(activeChapterTypeId)
    var newChapterTypeIndex = (activeChapterTypeIndex + increment) % availableChapterTypes.length
    if(newChapterTypeIndex < 0) newChapterTypeIndex = availableChapterTypes.length-1
    const newChapterTypeId = availableChapterTypes[newChapterTypeIndex]

    const chapterTypeDefaults = this.props.chapterTypes[newChapterTypeId].defaults

    // Update chapter with new type
    this.props.firestore.update({ collection: 'chapters' , doc: this.props.activeChapterId}, {
      ...chapterTypeDefaults,
      typeId:newChapterTypeId
    })

  }

  addBlock = (block) => {
    const {activeChapterBlocks} = this.props
    var highestOrderInBlocks = 0
    activeChapterBlocks.forEach(block => highestOrderInBlocks = block.order > highestOrderInBlocks ? block.order : highestOrderInBlocks) // Iterate through blocks and find the highest order

    const newOrder = highestOrderInBlocks + 2**20

    const defaultBlock = {
      typeId: 'default',
      value: null,
      order: newOrder,
      createdAt:firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt:firebase.firestore.FieldValue.serverTimestamp()
    }
    const mergedBlock = {...defaultBlock, ...block}
    this.props.firestore.add({ collection: 'blocks' }, mergedBlock )
  }

  updateBlockValue = (blockId, newValue) => {
    if(!blockId || !newValue) return null
    this.props.firestore.update({ collection: 'blocks', doc: blockId}, {value:newValue} )
  }

  handleSwiped = (eventData) => {
    const {deltaX, dir} = eventData
    this.setState({swipingTo:{name:false}})
    if(!this.props.canEdit || !this.props.availableChapterTypes || dir === 'Down' || dir === 'Up') return
    const indexDelta = Math.min(Math.abs(Math.floor(deltaX / 50)), this.props.availableChapterTypes.length)
    const index = indexDelta * (dir === 'Left' ? -1 : 1)
    this.changeChapterType(index)
  }

  chapterAtOffset = (increment) => {
    if(!this.props.availableChapterTypes) return null

    // Get active chapter
    const activeChapter = this.props.activeChapter
    const activeChapterTypeId = activeChapter.typeId ? activeChapter.typeId : this.props.defaultChapterTypeId
    const activeChapterTypeIndex = this.props.availableChapterTypes.indexOf(activeChapterTypeId)

    // Calculate new index in available chapter types
    var newTypeIndex = (activeChapterTypeIndex + increment) % this.props.availableChapterTypes.length
    if(newTypeIndex < 0) newTypeIndex = this.props.availableChapterTypes.length-1

    // Return new index and chapter type
    return {index:newTypeIndex, chapterTypeId:this.props.availableChapterTypes[newTypeIndex]}
  }

  handleSwiping = (eventData) => {

    const {deltaX, dir} = eventData
    if(dir === 'Down' || dir === 'Up') return
    const indexDelta = Math.min(Math.abs(Math.floor(deltaX / 50)), this.props.availableChapterTypes.length)

    const index = indexDelta * (dir === 'Left' ? -1 : 1)

    const chapterTypeId = this.chapterAtOffset(index).chapterTypeId
    const chapterType = this.props.chapterTypes[chapterTypeId]

    if(!this.state.swipingTo.chapterType || this.state.swipingTo.chapterType.name !== chapterType.name) this.setState({swipingTo:{chapterType, dir}})

  }

  render() {

    console.log('Rendered controller', this.props.storyStyle)

    var {chaptersOrdered, storyStyle} = this.props

    if(!chaptersOrdered || !this.props.chapterTypes) return null
    if(chaptersOrdered.length === 0 && this.props.canEdit) return <AddChapter onClick={this.addChapter}/>
    return(
      <Swipeable style={{width:'100%', overflow:'hidden'}} onSwiping={this.handleSwiping} onSwiped={this.handleSwiped} >

        {this.props.canEdit && this.state.swipingTo.chapterType &&
          <SwipingOverlay swipingTo={this.state.swipingTo}/>
        }

        {this.props.canEdit && <ChapterControls actions={[
          {text:'Add', action:this.addChapter},
          {text:'Delete', action:this.deleteChapter},
          {text:'Change', action:this.changeChapterType},
          {text:'Move Up', action:this.moveChapterUp}
        ]} changeChapterType={this.changeChapterType} addChapter={this.addChapter} deleteChapter={this.deleteChapter} moveChapterUp={this.moveChapterUp}/>}

        {
          chaptersOrdered.map((chapter, index) => {
            const chapterType = this.props.chapterTypes[chapter.typeId]
            return (
              <Chapter
                {...chapter}
                canEdit={this.props.canEdit}
                changeChapterType={this.changeChapterType}
                addChapter={this.addChapter}
                chapterType={chapterType}
                chapterIndex={index}
                storyStyle={storyStyle}
                id={chapter.id}
                key={chapter.id}
              />
            )
          })
        }
        {this.props.canEdit && <AddChapter onClick={this.addChapter}/>}

      </Swipeable>
    )
  }
}



const mapStateToProps = (state, ownProps) => {

  const chapters = state.firestore.data.chapters
                 ? state.firestore.data.chapters
                 : []

  const activeChapterId = state.nav.activeChapter

  return {
    availableChapterTypes: state.firestore.data.activeStory && state.firestore.data.activeStory.availableChapterTypes,
    defaultChapterTypeId: state.firestore.data.activeStory && state.firestore.data.activeStory.defaultChapterTypeId,
    chaptersOrdered: state.firestore.ordered.chapters,
    chapterTypes: state.firestore.data.chapterTypes,
    activeChapterId: state.nav.activeChapter,
    activeChapter: chapters[activeChapterId],
    activeChapterBlocks:
      state.firestore.ordered['blocksByChapter.' + activeChapterId]
        ? state.firestore.ordered['blocksByChapter.' + activeChapterId]
        : [],
    canEdit: state.firestore.data.activeStory && state.firestore.data.activeStory.writers && state.firestore.data.activeStory.writers.includes(state.firebase.auth.email),
    selectedBlock: state.nav.selectedBlock
  }
}

const mapDispatchToProps = {}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'chapters',
        where: ['storyId', '==', props.storyId],
        orderBy:'order'
      },
      {
        collection: 'chapterTypes',
      }
    ]
  }),
)(ChapterController)
