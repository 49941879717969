import React, { Component } from 'react';
import { firebase, db  } from '../../../config/constants'

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};


class PdfViewer extends Component {

  constructor(props){
    super(props)
    this.myInput = React.createRef()
  }

  state = {
    file: null,
    numPages: null,
    pageNumber: 1,
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  componentDidMount() {
    console.log(this.props.original)
  }

  render() {
    console.log('Render pdf', this.props.narrow)
    const { file, numPages } = this.state;

    return (
      <div style={{ ...this.props.style, width:'100%', maxWidth:'70vw', margin:'auto', borderRadius:16 }}
           onMouseEnter={this.props.onMouseEnter}
           onMouseLeave={this.props.onMouseLeave}
           className="Example">
           {this.props.children}
          <div ref={this.myInput} style={{width:'100%'}} className="Example__container__document">
            <Document
              file={this.props.value}
              onLoadSuccess={this.onDocumentLoadSuccess}
              options={options}
            >
              {
                Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <Page
                      width={this.myInput.current ? this.myInput.current.offsetWidth : 0}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  ),
                )
              }
            </Document>
          </div>


      </div>
    );
  }
}

export {PdfViewer}
