import React, { Component } from 'react'

var getScroll = () => {
  return(window.pageYOffset)
}

var getWindowHeight = () => {
  return window.innerHeight
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export default class ProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {progress:0}
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = debounce(() => {
    // console.log('Scolled')
    const progress = getScroll() / (document.body.clientHeight - getWindowHeight())
    this.setState({progress:progress})
  }, 30)

  render() {
    return(
      <div style={{
        position:'fixed',
        zIndex:20,
        left:0,
        top:0,
        width:'100%',
        height:3
      }}>
        <div
          style={{
            width:this.state.progress * 100 + '%',
            height:3,
            backgroundColor:this.props.color
          }}
          // transition={{
          //   duration:.1,
          //   ease: "easeOut"
          // }}
          />
      </div>
    )
  }
}
