import React, { Component } from 'react'
import ReactDOM from 'react-dom'
// import ContentEditable from 'react-contenteditable'
// import {Editor, EditorState} from 'draft-js';

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import { db, firebase } from '../../config/constants'

import { selectBlock, addBlockRef, removeBlockRef } from '../../redux/actions'

import styled from 'styled-components'

import {
  Detail,
  CoverImage,
  FixedImage,
  Heading,
  HeadingLarge,
  Image,
  Quote,
  Spotify,
  Vimeo,
  PdfViewer,
  OpenButton,
  VideoInline,
  VideoFixed,
  VideoFill,
} from './BlockTypes/'
import Button from '../Button'
import Icon from '../Icon'

import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import './ContextMenuStyle.css'

import close from '../../graphics/close2.svg'

const ButtonGroup = styled.div((props) => ({
  position: 'absolute',
  zIndex: 30,
  right: 16,
  top: 16,
}))

class Block extends Component {
  constructor(props) {
    super(props)
    this.state = { mouseOver: false, hasBeenActive: false }
    // this.ref = React.createRef()
  }

  // componentDidMount() {
  //   this.props.addBlockRef(this.ref)
  // }
  //
  // componentWillUnmount() {
  //   this.props.removeBlockRef(this.ref)
  // }

  handleMouseEnter = () => {
    this.setState({ mouseOver: true })
  }

  handleMouseLeave = () => {
    this.setState({ mouseOver: false })
  }

  removeBlock = () => {
    // If it's an uploaded file, we need to remove the file in storage
    if (this.props.original) {
      const { path } = this.props.original
      const storageRef = firebase.storage().ref()
      var fileRef = storageRef.child(path)

      // Delete the file
      fileRef
        .delete()
        .then(() => {
          // Then we can delete the db item
          db.collection('blocks').doc(this.props.blockId).delete()
        })
        .catch((e) => {
          // Uh-oh
          console.log('Error removing file:', e)
        })
    } else db.collection('blocks').doc(this.props.blockId).delete()
  }

  changeBlockType = () => {
    const increment = 1

    const type = this.props.type ? this.props.type : 'p'
    const types = ['p', 'h1', 'image', 'spotify']

    const currentTypeIndex = types.indexOf(type)
    var newTypeIndex = (currentTypeIndex + increment) % types.length
    if (newTypeIndex < 0) newTypeIndex = types.length - 1

    const newType = types[newTypeIndex]

    db.collection('blocks').doc(this.props.blockId).update({ type: newType })
  }

  handleClick = (e) => {
    console.log(e.offset)
    this.props.selectBlock(this.props.blockId)
    e.stopPropagation()
  }

  handleMenuClick = (e, data) => {
    console.log('data', e, data)
    switch (data.action) {
      case 'delete':
        this.removeBlock()
        break
      case 'change':
        this.changeBlockType()
        break
    }
  }

  render() {
    // if(this.props.isActive && !this.state.hasBeenActive) this.setState({hasBeenActive:true})
    if (
      (this.props.isActive || this.props.nearViewport) &&
      !this.state.hasBeenActive
    )
      this.setState({ hasBeenActive: true })

    const SpecificBlock = blockTypes[this.props.type]
      ? blockTypes[this.props.type]
      : blockTypes['default']
    return (
      // This is the code that enables right click context menu, but the problem is
      // When we wrap the block in this, it can't stretch to fill the chapter container ... Commenting out until solution found ...

      // <ContextMenuTrigger >
      //
      // <ContextMenu id={'block_menu'+this.props.blockId}>
      //   <MenuItem data={{action: 'delete'}} onClick={this.handleMenuClick}>
      //     Delete
      //   </MenuItem>
      // </ContextMenu>

      <SpecificBlock
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
        hide={!this.state.hasBeenActive}
        style={{ position: 'relative', cursor: 'grab' }}
        {...this.props}
      >
        {
          // this.state.mouseOver && this.props.canEdit &&
          //   <ButtonGroup>
          //     <Button
          //       onClick={this.removeBlock}
          //       hoverColor={'#C93A36'}
          //       style={{marginBottom:8}}
          //       icon={<Icon src={close}/>}
          //       size={36}/>
          //
          //     <Button onClick={this.changeBlockType} hoverColor={'#5930E5'} size={36}></Button>
          //   </ButtonGroup>
        }

        {this.props.children}

        <BorderBox
          isSelected={this.props.isSelected}
          canEdit={this.props.canEdit}
          mouseOver={this.state.mouseOver}
        />
      </SpecificBlock>

      //  </ContextMenuTrigger>
    )
  }
}

const BorderBox = ({ canEdit, isSelected, mouseOver }) => (
  <>
    <div
      style={{
        width: 3,
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        background: isSelected ? '#5930E5' : '#ddd',
        opacity: isSelected ? 1 : 0.9,
        display: (mouseOver || isSelected) && canEdit ? 'inline' : 'none',
      }}
    />
    <div
      style={{
        width: 3,
        height: '100%',
        position: 'absolute',
        left: 'calc(100% - 3px)',
        top: 0,
        background: isSelected ? '#5930E5' : '#ddd',
        opacity: isSelected ? 1 : 0.9,
        display: (mouseOver || isSelected) && canEdit ? 'inline' : 'none',
      }}
    />
    <div
      style={{
        width: '100%',
        height: 3,
        position: 'absolute',
        left: 0,
        top: 0,
        background: isSelected ? '#5930E5' : '#ddd',
        opacity: isSelected ? 1 : 0.9,
        display: (mouseOver || isSelected) && canEdit ? 'inline' : 'none',
      }}
    />
    <div
      style={{
        width: '100%',
        height: 3,
        position: 'absolute',
        left: 0,
        top: 'calc(100% - 3px)',
        background: isSelected ? '#5930E5' : '#ddd',
        opacity: isSelected ? 1 : 0.9,
        display: (mouseOver || isSelected) && canEdit ? 'inline' : 'none',
      }}
    />
  </>
)

const blockTypes = {
  default: Detail,
  h1: Heading,
  h2: HeadingLarge,
  p: Detail,
  quote: Quote,
  image: Image,
  imageFixed: FixedImage,
  video: VideoInline,
  videoFixed: VideoFixed,
  videoFill: VideoFill,
  coverImage: CoverImage,
  spotify: Spotify,
  vimeo: Vimeo,
  pdf: PdfViewer,
  openButton: OpenButton,
}

const mapStateToProps = (state, ownProps) => {
  return {
    canEdit:
      state.firestore.data.activeStory &&
      state.firestore.data.activeStory.writers &&
      state.firestore.data.activeStory.writers.includes(
        state.firebase.auth.email
      ),
    isActive:
      ownProps.chapterId === state.nav.activeChapter || ownProps.isActive,
    isSelected: state.nav.selectedBlock === ownProps.blockId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectBlock: (id) => {
      dispatch(selectBlock(id))
    },
    // addBlockRef: (ref) => {
    //   dispatch(addBlockRef(ref))
    // },
    // removeBlockRef: (ref) => {
    //   dispatch(removeBlockRef(ref))
    // },
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Block)
