import React, {Component} from 'react'
import styled from 'styled-components'

import { ImageStyled } from './Common'


const Vimeo = ({children, ...props}) => {

  const src = `https://player.vimeo.com/video/${props.value}`
  return <ImageStyled onClick={() => console.log('click')} {...props}>
    <iframe src={src} style={{objectFit:'contain', width:600, height:500, maxWidth:'100%', maxHeight:'80vh'}} frameBorder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    {children}
  </ImageStyled>
}



export { Vimeo }
