import { createStore, compose } from 'redux'
import { initialState, rootReducer } from './reducers'

var enhancers = []

const reduxDevToolsExtension = window.devToolsExtension
if (
  process.env.NODE_ENV === "development" &&
  typeof reduxDevToolsExtension === "function"
) {
  enhancers.push(reduxDevToolsExtension())
}

export default () => {
  return createStore(
    rootReducer,
    initialState,
    compose(...enhancers)
  )
}
