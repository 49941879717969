import React, { Component } from 'react';
// import logo from '../graphics/airpict_logo8.svg';

export default class NotFound extends Component {
  render() {
    return(

            <h1>Oops, nothing there :(</h1>

      
    )
  }
}
