import React, { useState } from 'react'
import useForm from 'react-hook-form'
import { login, resetPassword, signUpWithGoogle } from '../helpers/auth'
import styled from 'styled-components'
import { Redirect } from 'react-router-dom'

const Form = (props) => {
  const { handleSubmit, register, errors } = useForm()
  const [errorMessage, setErrorMessage] = useState(null)

  const onSubmit = (values) => {
    return login(values.email, values.password)
      .then(() => {
        return <Redirect to={{ pathname: props.redirectedFrom }} />
        console.log('Success!')
      })
      .catch((error) => {
        console.log('error')
        setErrorMessage(error.message)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        placeholder="Enter your email"
        name="email"
        ref={register({
          required: 'Required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'invalid email address',
          },
        })}
      />
      {errors.email && errors.email.message}

      <Input
        placeholder="... and password"
        type="password"
        name="password"
        ref={register({
          required: 'Required',
        })}
      />
      {errors.username && errors.username.message}
      <Button type="submit">Log in</Button>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </form>
  )
}

const Input = styled.input((props) => ({
  borderRadius: 6,
  margin: '6px 0px',
  display: 'inline-block',
  border: '2px solid #ddd',
  background: '#fff',
  width: '100%',
  textAlign: 'center',
  height: 40,
  boxSizing: 'border-box',
  outline: 'none',
  ':hover': {
    border: '2px solid #ccc',
  },
  ':focus': {
    border: '2px solid #5930E5',
  },
  '::placeholder': {
    color: 'ccc',
    textAlign: 'center',
  },
}))

const Button = styled.button((props) => ({
  borderRadius: 8,
  margin: '6px 0px',
  width: '100%',
  boxSizing: 'border-box',
  height: 40,
  border: '2px solid #ddd',
  cursor: 'pointer',
  ':hover': {
    background: '#5930E5',
    border: '2px solid #5930E5',
  },
}))

const Main = styled.div((props) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ErrorMessage = styled.div((props) => ({
  fontSize: 13,
  fontWeight: 400,
  color: '#f33',
  padding: 16,
}))

const Container = styled.div((props) => ({
  width: 300,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

function Login(props) {
  return (
    <Main>
      <Container>
        <Form
          redirectedFrom={props.location.state && props.location.state.from}
        />
      </Container>
    </Main>
  )
}

export default Login
