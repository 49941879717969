import {
  ADD_BLOCK_REF,
  REMOVE_BLOCK_REF,
} from './actions'


export default function nav(state = {blockRefs:[]}, action) {
  switch (action.type) {
    case ADD_BLOCK_REF:
      return Object.assign({}, state, {blockRefs: state.blockRefs.concat(action.ref)})
    case REMOVE_BLOCK_REF:
      return Object.assign({}, state, {blockRefs: state.blockRefs.filter(r => r !== action.ref)})
    default:
      return state
  }
}
