import React, {Component} from 'react'
import styled from 'styled-components'

const StyledCover = styled.div(props => ({
  // display: props.hide ? 'none' : 'flex',
  // opacity: props.hide ? 0 : 1,
  width:'100%',
  maxWidth:'100%',
  // height:'100%',
  minHeight:'33vh',
  // display:'flex',
  flex: '1 1 0',
  alignItems:'stretch',
  justifyContent:'center',
  backgroundImage:`url(${props.value})`,
  backgroundSize:'cover',
  backgroundPosition:'center',
  transition: 'opacity 2s ease',
  '@media (max-width: 1100px)': {
  },
  '@media (max-width: 768px)': {
  },
}))

const CoverImage = ({children, ...props}) =>
  <StyledCover {...props}>
    {children}
  </StyledCover>



export { CoverImage }
