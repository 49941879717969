import React, {Component} from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { LazyLoad } from './Common'

const landscapeWindow = window.innerHeight > window.innerWidth

const StyledCover = styled.div(props => ({
  width:'100%',
  height:'100%',
  maxWidth:'100%',
  minHeight:'33vh',
  flex: '1 1 0',
  transition: 'opacity 2s ease',
  position:'relative',
  overflow:'hidden',
  '@media (max-width: 1100px)': {
  },
  '@media (max-width: 768px)': {
  },
}))

const VideoFill = ({children, ...props}) =>
    <StyledCover>
      <LazyLoad hide={!props.nearViewport}>
        <div style={{position:'absolute', height:'100%', width:'100%', display:'flex',alignItems:'center', flexDirection:'column'}}>
          <ReactPlayer
            onError={(e) => console.log('Playback error', e)}
            style={{
              flex:'1 1 0',
              minHeight:'33vh',
              // width:landscapeWindow ? '100%' : 'auto',
              // height:landscapeWindow ? 'auto' : '100%',
              opacity:props.opacity,
              ...props.style
            }}
            width={landscapeWindow ? 'auto' : 'auto'}
            height={landscapeWindow ? '100%' : '100%'}
            url={props.value}
            playing={props.isActive}
            playsinline
            loop
            muted
            config={{ file: { attributes: {
              autoPlay: true,
              muted: true
            }}}}
          />
          {children}
        </div>
      </LazyLoad>
    </StyledCover>


export { VideoFill }
