import React, { useRef, useState } from 'react'
import { motion } from "framer-motion"
import styled from 'styled-components'

import  StoryCard from './StoryCard'

const Container = styled.div(props => ({
  textAlign:'center',
  maxWidth:'80%'

}))

const StoryList = (props) => {

  return(
    <Container>
      {props.stories.map((story, i) => <StoryCard i={i} key={i} handleClickDuplicate={props.handleClickDuplicate} handleClickOpen={props.handleClickOpen} handleClickDelete={props.handleClickDelete} story={story}/>)}
    </Container>
  )
}

export default StoryList
