export const SET_ACTIVE_CHAPTER = 'SET_ACTIVE_CHAPTER'
export const SELECT_BLOCK = 'SELECT_BLOCK'
export const ADD_BLOCK_REF = 'ADD_BLOCK_REF'
export const REMOVE_BLOCK_REF = 'REMOVE_BLOCK_REF'

export function setActiveChapter(chapterId) {
  return { type: SET_ACTIVE_CHAPTER, chapterId }
}

export function selectBlock(blockId) {
  return { type: SELECT_BLOCK, blockId }
}

export function addBlockRef(ref) {
  return { type: ADD_BLOCK_REF, ref }
}

export function removeBlockRef(ref) {
  return { type: REMOVE_BLOCK_REF, ref }
}
