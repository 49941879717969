import React, {Component} from 'react'
import { LazyLoad, ImageStyled } from './Common'
import ReactPlayer from 'react-player'

export const VideoInline = ({children, style, isSelected, ...props}) => {

  return (
    <ImageStyled {...props}>
      <LazyLoad hide={!props.nearViewport}>

        <ReactPlayer
          onError={(e) => console.log('Playback error', e)}
          style={{objectFit:'contain', display:'block', width:'100%', maxWidth:'100%', maxHeight:'80vh', ...style}}
          width='100%'
          height='auto'
          url={props.value}
          playing={props.isActive}
          playsinline
          loop
          muted
          config={{ file: { attributes: {
            autoPlay: true,
            muted: true
          }}}}
        />

        {children}

      </LazyLoad>
    </ImageStyled>
  )
}
