import React, {Component} from 'react'
import ReactPlayer from 'react-player'
import { LazyLoad } from './Common'

export const VideoFixed = ({children, ...props}) => {

  // This isn't responsive to resize events ...
  const landscapeWindow = window.innerHeight < window.innerWidth

  return(

    <LazyLoad hide={!props.isActive}>
      <div style={{display:props.isActive ? 'flex' : 'none', overflow:'hidden', position:props.isActive ? 'fixed' : 'absolute', zIndex:0, left:0, top:0, alignItems:'center', justifyContent:'center', width:'100%', height:'100vh'}}>

        <ReactPlayer
          onError={(e) => console.log('Playback error', e)}
          style={{
            objectFit:'cover',
            height:'100vh',
            width:'100vw',
            // display:'block',
            // width:landscapeWindow ? '100%' : 'auto',
            // height:landscapeWindow ? 'auto' : '100%',
            // display:props.isActive ? 'flex' : 'none',
            opacity:props.opacity,
            ...props.style,
          }}
          width={'auto'}
          height={'100%'}
          url={props.value}
          playing={props.isActive}
          playsinline
          loop
          muted
          config={{ file: { attributes: {
            autoPlay: true,
            muted: true
          }}}}
        />

      </div>
    </LazyLoad>

  )
}
