import React, {Component} from 'react'
import styled from 'styled-components'

import { LazyLoad, WidthContraint } from './Common'


const DetailText = styled.div(props => ({
  width:'100%',
  lineHeight:1.6,
  fontSize:20,
  fontWeight:400,
  alignSelf:props.left && 'flex-start',
  '@media (max-width: 1300px)': {
    fontSize:18,
    marginLeft:16,
    marginRight:16
  },
  '@media (max-width: 800px)': {
    fontSize:16,
  }
}))

const Detail = ({children, ...props}) => {

  const boldPieces = props.value.match(/\*\*(.*?)\*\*/g)
  const italicPieces = props.value.match(/\*(.*?)\*/g)

  const replacements = [
    {
      replace:'**',
      in:'<b>',
      out:'</b>'
    },
    {
      replace:'*',
      in:'<i>',
      out:'</i>'
    }
  ]
  let replaced = props.value

  replacements.forEach(replacement => {
    replaced = replaced.split(replacement.replace).map((bit, index) => {
      if(index % 2 === 0) {
        return bit
      }
      else {
        return replacement.in + bit + replacement.out
      }
    }).join('')
  })


  return(
    <WidthContraint>
      <DetailText id={props.blockId} {...props}>
        <LazyLoad hide={props.hide}>
          <div dangerouslySetInnerHTML={{ __html: replaced }} />
        {children}
        </LazyLoad>
      </DetailText>
    </WidthContraint>
  )
}



// function Detail(props) {
//   const [editorState, setEditorState] = React.useState(
//     EditorState.createEmpty()
//   );
//
//   console.log(editorState)
//
//   const editor = React.useRef(null);
//
//   function focusEditor() {
//     editor.current.focus();
//   }
//
//   React.useEffect(() => {
//     focusEditor()
//   }, []);
//
//   return (
//     <div onClick={focusEditor}>
//       <Editor
//         ref={editor}
//         editorState={editorState}
//         onChange={editorState => setEditorState(editorState)}
//       />
//     </div>
//   );
// }



  // <Editable {...props}></Editable>


// const Detail = ({children, ...props}) =>
//   <textarea id={props.blockId} style={{}} contentEditable={true} className={css(styles.p, styles.editable)}>{props.value}</textarea>
//
// p: {
//   lineHeight:1.6,
//   fontSize:20,
//   fontWeight:400,
//   maxWidth:'50ch',
//   // textAlign:'left',
//   // padding:16,
//   '@media (max-width: 1300px)': {
//     fontSize:19,
//
//   },
//   '@media (max-width: 768px)': {
//     fontSize:18,
//     // textAlign:'center',
//   }
// },

export { Detail }
