import React, { Component } from 'react'
import styled from 'styled-components'

import Button from './Button'

const ModalWindow = styled.div(props => ({
  position:'fixed',
  zIndex:10,
  width:600,
  height:200,
  maxWidth:'80%',
  left:'calc(50% - 300px)',
  top:'calc(50% - 100px)',
  // border:'2px solid #ccc',
  boxShadow:'0 6px 40px 8px rgba(0,0,0,0.08)',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  flexDirection:'column',
  padding:'20px 80px',
  boxSizing:'border-box',
  borderRadius:16,
  backgroundColor:'#fff',
}))

const ButtonGroup = styled.div(props => ({
  display:'flex',
  width:'100%',
  justifyContent:'center'
}))

const Text = styled.div(props => ({
  fontSize:14,
  margin:8,
  fontWeight:600,
  textAlign:'center'
}))

export default class Modal extends Component {

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {

    // If click is inside, carry on
    if(this.node.contains(e.target)) {
      return
    }

    // If click is outside
    this.props.handleClickOutside()
  }

  render() {
    return(
      <div ref={node => this.node = node}>
        <ModalWindow>
          <Text>{this.props.text}</Text>
          <ButtonGroup>
            <Button onClick={this.props.handleClickNo} style={{flex:'1 1 0'}}>No</Button>
            <Button onClick={this.props.handleClickYes} color={'#C54E35'} style={{flex:'1 1 0'}}>Yes</Button>
          </ButtonGroup>
        </ModalWindow>
      </div>
    )
  }
}
