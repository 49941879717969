import React, {Component} from 'react'
import styled from 'styled-components'

const QuoteStyled = styled.div(props => ({
  textAlign:'center',
  fontSize:30,
  fontWeight:800,
  width:'100%',
  lineHeight:1.5,
  fontStyle:'italic',
  '@media (max-width: 1300px)': {
    textAlign:'center',
  },
  '@media (max-width: 768px)': {

    textAlign:'center',
    lineHeight:1.1,
  }
}))

const Quote = ({children, ...props}) =>
  <QuoteStyled id={props.blockId} {...props}>
    {props.value}
  </QuoteStyled>

export { Quote }
