import React, {Component} from 'react'
import styled from 'styled-components'


const StyledH1 = styled.div(props => ({
  fontFamily:'ITC-Clearface',
  textAlign:'center',
  fontSize:100,
  fontWeight:500,
  lineHeight:1.4,
  margin:32,
  '@media (max-width: 1300px)': {
    fontSize:80,
    textAlign:'center',
  },
  '@media (max-width: 768px)': {
    fontSize:50,
    textAlign:'center',
    lineHeight:1.1,
  }
}))

const NoBreak = styled.div(props => ({
  whiteSpace:'nowrap'
}))


const HeadingLarge = ({children, ...props}) => {

  const words = props.value.split(' ')

  const splitPoint = words.length > 3 ? words.length-2 : words.length

  const first = words.slice(0, splitPoint).join(' ')
  const lastTwo = words.slice(splitPoint,words.length).join(' ')

  return(
    <StyledH1
      id={props.blockId}
      style={{...props.style}}
      {...props}
    >
      {first}
      <NoBreak>
        {lastTwo}
      </NoBreak>
      {children}
      </StyledH1>
  )
}


export { HeadingLarge }
