import React, {Component} from 'react'
import styled from 'styled-components'
import { StyleSheet, css } from 'aphrodite'

const ColumnFill = styled.div(props => ({
  width:'100%',
  flex:'1 0 0',
  minHeight:'100vh',
  display:'flex',
  flexDirection:'column'
}))


const Column = styled.div(props => ({
  flex:'1 1 0',
  display:'flex',
  alignItems:'center',
  // justifyContent:'center',
  flexDirection:'column',
  '@media (max-width: 1100px)': {
  },
  '@media (max-width: 768px)': {
    // paddingLeft:16,
    // paddingRight:16,
  },
}))


const Cover = ({children, ...props}) =>
  <div className={css(styles.cover)} style={{backgroundImage:`url(${props.image})`, backgroundPosition:'center', backgroundSize:'cover', ...props.style}} >
    {children}
  </div>

const SplitImageFill = ({children, ...props}) =>
<div className={css(styles.splitImageFill, props.horisontal && styles.horisontal)} style={props.style}>
  {children}
</div>

const Split = ({children, ...props}) =>
  <div className={css(styles.split)} style={props.style}>
    {!props.horisontal
      ?
        <div className={css(styles.blockInner)}>
          {children}
        </div>
      : children
    }
  </div>


const SplitWide = ({children, ...props}) =>
  <div className={css(styles.split)} style={props.style}>
    <div className={css(styles.blockInner, styles.wide)}>
      {children}
    </div>
  </div>

const Section = ({children, ...props}) =>
  <div
    className={css(
      styles.section,
      props.wide ? styles.wide : props.narrow ? styles.narrow : styles.borderless,
      props.reversed && styles.sectionReversed
    )}
    style={{...props.style}}>
      {children}
  </div>


const Heading = ({children, ...props}) => {

  const words = children.split(' ')

  const splitPoint = words.length > 2 ? words.length-2 : words.length

  const first = words.slice(0, splitPoint).join(' ')
  const lastTwo = words.slice(splitPoint,words.length).join(' ')

  return(
    <div id={props.id} className={css(styles.h2, styles.editable)}>
      {first}
      <div className={css(styles.noBreak)}>
      {lastTwo}
      </div>
    </div>
  )

}


const Row = styled.div(props => ({
  // minHeight: props.hasMinimumHeight && '70vh',
  marginTop: props.hasMinimumHeight && 100,
  marginBottom: props.hasMinimumHeight && 100,
  minHeight: props.hasMinimumHeight && 400,
  // padding: props.narrow && '0 80px 0 80px'
  width:'100%',
  paddingLeft: props.narrow && 80,
  paddingRight: props.narrow && 80,
  display: 'inline-flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'center',
  '@media (max-width: 1100px)': {
  },
  '@media (max-width: 768px)': {
    // flexDirection:'column'
    display: 'inline',
    padding:props.narrow && 10,
    // textAlign:'center'
    // padding: props.narrow && '0 180px 0 180px',
  },
}))











const styles = StyleSheet.create({
  cover: {
    height:'100vh',
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    padding:0,
  },

  coverBlock: {
    // height:'100vh', width:'100%', backgroundImage:`url(${props.contents.image})`, backgroundSize:'cover', backgroundPosition:'center'
  },
  hidden: {
    opacity:0,
    marginTop:150,
    transition:'opacity .7s ease, margin .5s ease'
  },
  noBreak: {
    whiteSpace:'nowrap'
  },


  section: {
    // marginTop:80,

    width:'100%',
    minHeight:'80vh',
    height:'auto',
    // maxHeight:'calc(100vh - 120px)',
    display: 'inline-flex',
    // alignItems: 'stretch',
    // justifyContent: 'left',
    // overflow:'hidden',
    padding:'60px 0px',
    boxSizing: 'border-box',
    '@media (max-width: 1300px)': {
      paddingTop:60,

    },
    '@media (max-width: 800px)': {
      paddingTop:60,
      // marginTop:60,
      flexDirection:'column',
      justifyContent: 'center',
    }
  },

  sectionReversed: {
    flexDirection:'row-reverse'
  },
  blockInner: {


    '@media (max-width: 1300px)': {
      // padding:50,
      // paddingTop:20,
      // paddingBottom:20,
    },
    '@media (max-width: 800px)': {
      // padding:50,
      // paddingTop:20,
      // paddingBottom:20,
      // textAlign:'center'
    }
  },
  split: {
    // zIndex:0
    // width:'100%',
    // height:'100%',
    // marginLeft:8,
    // marginRight:8,
    flex:'1 1 0',
    display:'inline-flex',
    // flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    '@media (max-width: 1300px)': {
      marginLeft:20,
      marginRight:20,
    },
    '@media (max-width: 800px)': {
      marginLeft:10,
      marginRight:10,
    }
  },
  horisontal: {
    flexDirection:'row',
  },
  splitImageFill: {
    flex:'1 1 0',
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'stretch',
  },
  narrow: {
    // width:'calc(100% - 120px)',
      paddingLeft:200,
      paddingRight:200,

    // width:'75ch',
    // width:'75ch',
    // maxWidth:'75ch',
    '@media (max-width: 1300px)': {
      paddingLeft:80,
      paddingRight:80,
    },
    '@media (max-width: 800px)': {
      // width:400,
      paddingLeft:40,
      paddingRight:40,
    }
  },
  wide: {
    paddingLeft:100,
    paddingRight:100,
    '@media (max-width: 1300px)': {
      paddingLeft:150,
      paddingRight:150,
    },
    '@media (max-width: 800px)': {
      // width:400,
      paddingLeft:40,
      paddingRight:40,
    }
    // width:'160ch',
    // maxWidth:'75%'
  },

  noBreak: {
    whiteSpace:'nowrap'
  },
  editable: {
    ':hover': {
      outline: '3px solid rgba(252,182,62,.2)',
    },
    ':focus': {
      outline: '3px solid rgba(252,182,62,.8)',
    },
  }

})

export {
  Cover, Row, Column, Section, Split, Heading, ColumnFill
}
