import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withRouter } from 'react-router'



const TitleInput = styled.input(props => ({
  background:'transparent',
  width:`${props.length+.2}ch`,
  fontSize:16,
  fontWeight:600,
  outline:'none',
  border:'none',
  color:'#fff',
  padding:'8px 8px',
  margin:8,
  borderBottom:'2px dashed transparent',

  ':hover': {
    borderBottom:'2px dashed #fff',
    color:'#fff'
  },
  ':focus': {
    background:'#fff',
    borderRadius:8,
    // borderBottom:'2px dashed #fff',
    color:'#000'
  },
  '::placeholder': {
    color:'ccc',
    textAlign:'center'
  },
}))


function StoryEditableTitle({title, firestore, storyId}) {
  return(

    <div
        style={{
          x:0,
          opacity:1,
        }}
      >
        <TitleInput
          onChange={(e) => firestore.update(`stories/${storyId}`, { title: e.target.value})}
          value={title}
          length={title.length}
        />

      </div>
  )
}


StoryEditableTitle.propTypes = {
  title: PropTypes.string.isRequired
}

StoryEditableTitle.defaultProps = {
  title: ''
}


const mapStateToProps = (state, ownProps) => {

 const story = state.firestore.data.activeStory ? state.firestore.data.activeStory : []

  return {
    title: story.title,
    storyId: ownProps.match.params.storyId,
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  firestoreConnect(),
)(StoryEditableTitle)
