import React, {Component} from 'react'
import styled from 'styled-components'

import { ImageStyled } from './Common'


const Spotify = ({children, ...props}) => {

  const src = props.value.split('.com/').join('.com/embed/')
  const isPlaylist = src.indexOf('playlist') !== -1

  return <ImageStyled {...props}>
    <iframe src={src} width="100%" height={isPlaylist ? 600 : 80} frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    {children}
  </ImageStyled>
}


export { Spotify }
