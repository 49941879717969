import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Menu from './Menu.js'
import Button from '../Button.js'
import { MoreHoriz } from '@styled-icons/material/MoreHoriz'

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 1, x: 0 },
}

function ChapterControls(props) {
  const [isOpen, setOpen] = useState(false)
  const containerRef = useRef(null)

  return (
    <div
      style={{
        position: 'fixed',
        width: 170,
        zIndex: 100,
        right: 32,
        bottom: 32,
      }}
      onMouseLeave={() => setOpen(false)}
    >
      <motion.nav
        key={'chapter_controls'}
        animate={isOpen ? 'open' : 'closed'}
        variants={variants}
        ref={containerRef}
      >
        <Menu actions={props.actions} />

        <div onMouseEnter={() => setOpen(true)} style={{ float: 'right' }}>
          <Button>
            <MoreHoriz style={{ height: 32 }} />
          </Button>
        </div>
      </motion.nav>
    </div>
  )
}

export default ChapterControls
