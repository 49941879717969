import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const theme = {
  background: '#fff',
  backgroundTinted1: '#f0f0f0',
  neutralTextKey1: '#000',
}

const colors = {
  accentColor2: '#4109e8',
  accentColor3: '#5930E5',
  accentColor4: '#4109e8',
}

const fonts = {
  primaryFont: 'UniversalSans',
}

const fontSizes = {
  xxxlarge: 100,
  xxlarge: 80,
  xlarge: 70,
  large: 50,
  mediumlarge: 40,
  medium: 30,
}

export const Page = styled.div((props) => ({
  fontFamily: fonts.primaryFont,
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  maxWidth: 1200,
  margin: 'auto',
  color: '#fff',
  fontWeight: 400,
  '@media (max-width: 1300px)': {},
  '@media (max-width: 800px)': {},
  ...props.style,
}))

export const Block = styled.div((props) => ({
  margin: 36,
  padding: 36,
  borderRadius: 32,
  ':hover': {},
  '@media (max-width: 1300px)': {},
  '@media (max-width: 800px)': {
    margin: 8,
    padding: 16,
  },
  ...props.style,
}))

export const Center = styled.div((props) => ({
  height: '100%',
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '@media (max-width: 1300px)': {},
  '@media (max-width: 800px)': {},
  ...props.style,
}))

export const Heading = styled.div((props) => ({
  fontFamily: fonts.headingFont,
  fontSize: fontSizes.xxlarge,
  fontWeight: 800,
  lineHeight: 1.1,
  marginTop: 36,
  '@media (max-width: 1300px)': {
    fontSize: fontSizes.xxlarge,
  },
  '@media (max-width: 800px)': {
    fontSize: fontSizes.xlarge,
    marginTop: 36,
  },
  ...props.style,
}))

export const Description = styled.div((props) => ({
  fontSize: fontSizes.large,
  fontWeight: 300,
  maxWidth: '26em',
  marginTop: 16,
  '@media (max-width: 1300px)': {
    fontSize: fontSizes.mediumlarge,
  },
  '@media (max-width: 800px)': {
    fontSize: fontSizes.medium,
    marginTop: 16,
  },
  ...props.style,
}))

export const Highlight = styled.span((props) => ({
  color: props.color,
  '@media (max-width: 1300px)': {},
  '@media (max-width: 800px)': {},
  ...props.style,
}))

export const VisualContainer = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  // maxHeight:400,
  margin: -16,
  marginTop: 32,
  '@media (max-width: 1300px)': {},
  '@media (max-width: 800px)': {
    margin: -8,
    marginTop: 36,
  },
  ...props.style,
}))

export const VisualBlock = styled.div((props) => ({
  borderRadius: 16,
  overflow: 'hidden',
  positon: 'relative',
  margin: 16,
  flex: '1 1 0',
  maxHeight: '60vw',
  height: props.height ? props.height : '30vw',
  backgroundImage: `url(${props.image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  // flex: '0 0 calc(50% - 0.5em)',
  '@media (max-width: 1300px)': {},
  '@media (max-width: 800px)': {
    margin: 8,
  },
  ...props.style,
}))

export const Button = styled.button((props) => ({
  borderRadius: 32,
  height: 60,
  fontSize: fontSizes.medium,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px 32px',
  border: 'none',
  color: '#fff',
  background: colors.accentColor3,
  outline: 'none',
  ...props.style,
  ':hover': {
    background: colors.accentColor2,
  },
  ':active': {
    background: colors.accentColor2,
  },
}))
