import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  compose,
  withHandlers,
  withContext,
  getContext,
} from 'recompose'
// import { firestoreConnect } from 'react-redux-firebase'
import { firebase, db  } from '../config/constants'
// import { withRouter } from 'react-router'
import Dropzone from 'react-dropzone'

// import { fonts, fontSizes, colors } from '../styles/shared'

// import { NativeTypes } from 'react-dnd-html5-backend'

import uploadImage from '../graphics/logo_loop_1.gif'


class Uploader extends Component {


  constructor(props, context) {
    super(props, context);
    this.onDrop = (droppedFiles) => {

      var totalSize = 0
      droppedFiles.forEach(file => {
        const {size, type} = file
        totalSize = totalSize + size
      })
      this.setState({droppedFiles:droppedFiles, totalUploadSize: totalSize})
      this.putUpload()

    }
    this.state = {droppedFiles:[], completedUploads:0, bytesTransferredByFile:{}, totalUploadSize:0}
  }


  batchUpload = (files, i, batchSize, chapterId) => {
    console.log('Creating new batch ', i, batchSize )
    const batch = files.slice(i, i + batchSize)
    var batchUploadPromises = []

    batch.forEach(file => {
      const filePromise = this.uploadFile(file, chapterId)
      batchUploadPromises.push(filePromise)
    })

    return Promise
      .all(batchUploadPromises)
      .then(() => {
        console.log('Batch finished.')

        const nextIndex = i + batchSize
        if(files.length > nextIndex) {
          const nextBatchSize = files.length > nextIndex + batchSize ? batchSize : files.length - nextIndex
          return this.batchUpload(files, nextIndex, nextBatchSize, chapterId)
        }
        else return
      })

  }

  uploadFile = (file, chapterId) => {

    // If there is no active workspace we can't upload
    if(!chapterId) return null

    const {size, type} = file

    const typeCategory = file.type.split('/')[0]
    const extension = file.type.split('/')[1]

    var storageRef = firebase.storage().ref();


    const formattedType = extension === 'pdf' ? 'pdf' : typeCategory


    if(['image', 'pdf', 'video'].includes(formattedType) && extension) {

      const blockRef = db.collection('blocks').doc()
      const uploadRef = storageRef.child(`originals/${blockRef.id}.${extension}`);

      var metadata = {
        // contentType: 'image/jpeg',
        contentType: type
        // customMetadata: {
        //   workspaceAccess:'reader',
        //   workspaceId: this.props.workspaceId, // this is what we will use in firebase security rules
        //   owner: this.props.uid
        // }
      }

      // Get an order number that is higher than every other chapter block
      const {activeChapterBlocks} = this.props
      var highestOrderInBlocks = 0
      activeChapterBlocks.forEach(block => highestOrderInBlocks = block.order > highestOrderInBlocks ? block.order : highestOrderInBlocks) // Iterate through blocks and find the highest order
      const newOrder = highestOrderInBlocks + 2**20

      var block = {
        chapterId,
        type:formattedType,
        order: newOrder,
        original: {
          bucketName:'airpict.appspot.com',
          path: `originals/${blockRef.id}.${extension}`
        },
        origin: {
          path: file.path
        },
        createdAt:firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt:firebase.firestore.FieldValue.serverTimestamp()
      }



      var uploadTask = uploadRef.put(file, metadata)

      uploadTask
      .on('state_changed', (snapshot) => {

        const transferred = snapshot.bytesTransferred
        const updatedBytesTransferredByFile = {...this.state.bytesTransferredByFile, ...{[blockRef.id]:transferred}}

        this.setState({bytesTransferredByFile:updatedBytesTransferredByFile })

      })

      uploadTask
      .then(snapshot => {
        return snapshot.ref.getDownloadURL()
      })
      .then(downloadUrl => {
        block.value = downloadUrl
        blockRef.set(block)
        this.setState({completedUploads:this.state.completedUploads + 1})
        console.log('Uploaded file to! ', downloadUrl, blockRef.id);
      })

      return uploadTask
    }
  }


  putUpload = () => {

    const { droppedFiles } = this.state

    // Reset counters
    this.setState({uploadComplete:false, completedUploads:0, bytesTransferredByFile:0 })

    const batchUpload = this.batchUpload(droppedFiles, 0, 10, this.props.activeChapterId)
        .then(upload => {
          console.log('DONE: ', upload)

          this.setState({uploadComplete:true, droppedFiles:[], completedUploads:0 })

        })
        .catch(e => {
          console.log('Error with upload session:', e)
        })
  }


  render() {
    const { droppedFiles, bytesTransferredByFile } = this.state

    var totalBytesTransferred = 0
    Object.values(bytesTransferredByFile)
          .forEach(bytes => totalBytesTransferred = totalBytesTransferred + bytes)

    return(
        <Dropzone noClick onDrop={this.onDrop}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <div style={{outline:'none' }}{...getRootProps({className: 'dropzone'})}>


              {
                isDragActive
                ? <CenterContainer style={{width:'100%', height:'100%', position:'fixed', zIndex:100, background:'#FFF6EE', ...this.props.style}}>

                      <Heading color={'#000'}>
                        Drop it like it's 🔥
                      </Heading>

                  </CenterContainer>

                :
                droppedFiles.length > 0 &&
                  <CenterContainer style={{width:'100%', height:'100%', position:'fixed', zIndex:100, background:'#ddd', ...this.props.style}}>

                    <div>Uploading ... {Math.round((totalBytesTransferred * 100) / this.state.totalUploadSize)}%</div>

                  </CenterContainer>
              }
              {this.props.children}

            </div>
        )}

        </Dropzone>
    )
  }
}




const Heading = ({children, ...props}) =>
  <div style={{
    fontSize:40,
    fontWeight:700,
    ...props
  }}>
    {children}
  </div>

const CenterContainer = ({children, ...props}) =>
  <div style={{
      display:'flex',
      flexDirection:'column',
      width:'100%',
      height:'100vh',
      justifyContent:'center',
      alignItems:'center',
      textAlign:'center',
      ...props.style
      }}>

    {children}
  </div>



const mapStateToProps = (state, ownProps) => {
  return {
    activeChapterId: state.nav.activeChapter,
    activeChapterBlocks:
      state.firestore.ordered['blocksByChapter.' + state.nav.activeChapter]
        ? state.firestore.ordered['blocksByChapter.' + state.nav.activeChapter]
        : [],
  }
}

export default compose(
  connect(mapStateToProps),
)(Uploader)
