import React from 'react'
import logo from '../graphics/kive_logo.svg'
import Button from './Button'
import Icon from './Icon'

const Logo = ({ src = logo, link = '/s', style }) => (
  <div
    style={{
      display: 'inline-block',
      scale: 1,
      rotate: 0,
      margin: 0,
      ...style,
    }}
  >
    <a href={link}>
      <Icon src={src} />
    </a>
  </div>
)

export default Logo
