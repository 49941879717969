import { db, firebaseAuth, firebase } from '../config/constants';

export function auth(values) {
  return firebaseAuth()
    .createUserWithEmailAndPassword(values.email, values.password)
    .then(user => {
      // console.log('Updating displayName to ', values.fullname);
      console.log('Created user ', user)


      return saveUser(values, user.user.uid)
      // user.user.updateProfile({ displayName:values.fullname })

        // .then(() => console.log('Updated user db'))
        // .catch(e => console.log('Error updating user db: ', e))// XXX:
        // .then(() => console.log('Successfully added user.'))
        // .catch(e => console.log('Error: ', e))
    })
}

export function signUpWithGoogle(email) {
  var provider = new firebase.auth.GoogleAuthProvider();

  // If email was provided, use hint
  if(email) provider.setCustomParameters({
    'login_hint': email
  })

  firebase.auth().signInWithRedirect(provider);

  return firebase.auth().getRedirectResult()
    .then(result => {
      if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken
        // ...
      }
      // The signed-in user info.
      return result
    })
    .catch(error => {
      // Handle Errors here.
      console.log('Error Signing in with Google: ', error)
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
}

export function logout() {
  return firebaseAuth().signOut();
}

export function login(email, pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw)
}

export function resetPassword(email) {
  return firebaseAuth().sendPasswordResetEmail(email)
}

export function saveUser(values, uid) {

  var batch = db.batch()

  var userRef = db.collection('users').doc(uid)

  // Create user
  batch
    .set(userRef, {
      uid: uid,
      email: values.email,
    }, { merge:true })

  // Create a doc in user private for onboarding
  var onboardingRef = userRef.collection('private').doc('onboarding')

  // Init onboarding
  batch
    .set(onboardingRef, {
      completedSignup: true,
      completedWorkspace: false,
      completedPrivacy: false,
      completedProfile: false,
      completedUploads: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })

  // Return committed batch
  return batch.commit()
    .catch(e => console.log('Error: ', e))
}
