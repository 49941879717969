import React, { Component } from 'react'
import styled from 'styled-components'

const Icon = styled.div(props => ({size, background, src}) => ({
  maxWidth:size ? size : 28,
  maxHeight:size ? size : 28,
  width: size ? size : 28,
  height: size ? size : 28,
  backgroundColor:background ? background : 'transparent',
  // borderRadius:'50%',
  backgroundImage:`url(${src})`,
  backgroundSize:'100%',
  backgroundRepeat:'no-repeat',
  backgroundPosition:'center'
}))

export default Icon
