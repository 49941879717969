import React, { Component } from 'react'
import {withRouter} from 'react-router'
import styled from 'styled-components'

const HeaderContainer = styled.div(props => ({
  '@media (max-width: 1100px)': {
  },
  '@media (max-width: 768px)': {
    display:'none'
  },
}))

const Header = ({children, style}) =>
  <HeaderContainer>
  <div
      style={{
        width:'100%',
        position:'fixed',
        display:'flex',
        justifyContent:'start',
        alignItems:'center',
        zIndex:100,
        left:32,
        top:32,
        height:'auto',
        padding:0,
        ...style
      }}
    >
    {children}
  </div>
  </HeaderContainer>

export default withRouter(Header)
