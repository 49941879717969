import React, { Component } from 'react'
import styled from 'styled-components'

import Button from '../../Button'

const OpenButton = ({ children, ...props }) => {
  console.log('rendering openbutton', props)
  return (
    <a target="_blank" href={props.value} style={{ textDecoration: 'none' }}>
      <Button style={{ padding: 16 }} color={'#5930E5'}>
        Download {props.fileType}
      </Button>
    </a>
  )
}

export { OpenButton }
