import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { Plus } from '@styled-icons/boxicons-regular/Plus'

const Heading = styled.div((props) => ({
  fontSize: 20,
  fontWeight: 600,
  margin: 16,
  textAlign: 'center',
}))

const ButtonAnimator = ({ children, onClick }) => (
  <motion.div
    style={{
      height: 100,
      width: 100,
      margin: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      scale: 1,
      opacity: 1,
      backgroundColor: '#ddd',
      borderRadius: '50%',
      cursor: 'pointer',
    }}
    transition={{
      scale: { stiffness: 1, velocity: 10 },
    }}
    whileHover={{
      scale: 1.1,
      backgroundColor: '#5930E5',
    }}
    whileTap={{
      scale: 0.95,
      backgroundColor: '#5930E5',
    }}
    onClick={onClick}
  >
    {children}
  </motion.div>
)

const AddChapterButton = ({ onClick }) => (
  <ButtonAnimator onClick={onClick}>
    <Plus style={{ height: 32 }} />
  </ButtonAnimator>
)

function AddChapter({ onClick }) {
  return (
    <div
      style={{
        width: '100%',
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Heading>Add Chapter</Heading>
      <AddChapterButton onClick={onClick} />
    </div>
  )
}

export default AddChapter
