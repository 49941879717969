import React from 'react'
import { motion } from 'framer-motion'

const variants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 50, velocity: -500 },
    },
  },
  closed: {
    x: 50,
    opacity: 0,
    transition: {
      x: { stiffness: 50 },
    },
  },
}

const MenuItem = ({ action }) => {
  const style = {}
  return (
    <motion.li
      variants={variants}
      style={{
        margin: 8,
        cursor: 'pointer',
        backgroundColor: '#5930E5',
        color: '#000',
        display: 'inline-block',
        padding: 16,
        borderRadius: 32,
      }}
      whileHover={{
        scale: 1.05,
      }}
      whileTap={{ scale: 0.98 }}
      onClick={action.action}
    >
      <div className="icon-placeholder" style={style} />
      <div className="text-placeholder" style={style}>
        {action.text}
      </div>
    </motion.li>
  )
}

export default MenuItem
