import React, { Component } from 'react'
import { Route, BrowserRouter, Redirect, Switch } from 'react-router-dom'

import { logout } from '../helpers/auth'
import { firebaseAuth } from '../config/constants'

import Login from './Login'
import Loading from './Loading'
import Dashboard from './Dashboard/Dashboard'
import Story from './Story'
import NotFound from './NotFound'
import Landing from './landing/Landing'

import {PdfViewer} from './Chapter/BlockTypes/PdfViewer'

import FullStory from 'react-fullstory';


import './App.css';

function PrivateRoute ({component: Component, authed, setMemory, ...rest}) {
  if(!authed) setMemory(rest.location.pathname)
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

function PublicRoute ({component: Component, authed, memory, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === false
        ? <Component {...props} />
        : <Redirect to={`${memory ? memory : '/s'}`} />}
    />
  )
}

export default class Router extends Component {
  state = {
    authed: false,
    loading: true,
  }
  componentDidMount () {
    window.dataLayer = window.dataLayer || [];

    this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
      console.log('auth changed', user)
      if (user) {
        this.setState({
          authed: true,
          loading: false,
        })
      } else {
        this.setState({
          authed: false,
          loading: false
        })
      }
    })
  }
  componentWillUnmount () {
    this.removeListener()
  }

  setMemory = (pathname) => {
    this.setState({memory:pathname})
  }

  render() {
    return this.state.loading === true ? null : (

        <BrowserRouter>
            <FullStory org="PBWFX" />
              <Switch>
                <Route path='/logout' render={() => {
                  logout()
                }} />


                <PublicRoute authed={this.state.authed} memory={this.state.memory} path='/login' exact component={Login} />

                <Route path='/pdftest' exact component={PdfViewer} />

                <Route path='/s/:storyId' component={Story} />
                <Route path='/:spaceId/:storyId' component={Story} />

                <PrivateRoute authed={this.state.authed} setMemory={this.setMemory} path='/s/:storyId/edit' component={Story} />
                <PrivateRoute authed={this.state.authed} setMemory={this.setMemory} path='/:spaceId/:storyId/edit' component={Story} />

                <PrivateRoute authed={this.state.authed} setMemory={this.setMemory} path='/:spaceId' component={Dashboard} />
                <PrivateRoute authed={this.state.authed} setMemory={this.setMemory} path='/s' component={Dashboard} />

                <PublicRoute authed={this.state.authed} path='/' exact component={Landing} />


                <Route path='/404' component={NotFound} />

                <Route render={() => <h3>No Match</h3>} />
              </Switch>


        </BrowserRouter>

    );
  }
}
