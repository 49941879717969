import React, {Component} from 'react'
import styled from 'styled-components'
import { StyleSheet, css } from 'aphrodite'

import {Row, Column, Section, Split, Heading, ColumnFill, Cover} from './Shared'
import Block from '../Block'

function ifExistMerge(arrays) {

  const filter = arrays.filter(o => o && Array.isArray(o))
  return !filter || filter.length === 0 ? null : filter.reduce((a, b) => [...a, ...b])
}

const ImageFillChapter = props => {
  const {blocksByType, blocks} = props

  const {h1, p, image, video, vimeo, ...rest} = blocksByType

  const textBlocks = ifExistMerge([h1, p])
  // const imageBlocks = ifExistMerge([image])
  // const videoBlocks = ifExistMerge([video, vimeo])
  const restBlocks = rest ? ifExistMerge(Object.values(rest)) : []

  const imageAndVideoBlocks = ifExistMerge([video, vimeo, image])

  return(
    <Row {...props}>
      { textBlocks &&
        <Column>
          {textBlocks.map((block, index) => <><Block nearViewport={props.nearViewport} key={index} {...block}/><br/></>)}

        </Column>
      }
      {
        (imageAndVideoBlocks && props.nearViewport) && 
        <ColumnFill horisontal={props.horisontal}>
          {imageAndVideoBlocks.map((block, index) => {
            if(block.type === 'image') return <Block nearViewport={props.nearViewport} key={index} {...block} type={'coverImage'}/>
            else return <Block nearViewport={props.nearViewport} key={index} {...block} type={'videoFill'}/>
          })}

        </ColumnFill>
      }
      { restBlocks &&
        <Column>
          {restBlocks.map((block, index) => <Block key={index} {...block} fileType={block.type} type={'openButton'}/>)}
        </Column>
      }

    </Row>
  )
}

export default ImageFillChapter
