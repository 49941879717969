import React, {Component} from 'react'
import styled from 'styled-components'
import { StyleSheet, css } from 'aphrodite'

import {Row, Column, Section, Split, Heading, ColumnFill, Cover} from './Shared'
import Block from '../Block'


// var getScroll = () => {
//   return(window.pageYOffset)
// }
//
// var getWindowHeight = () => {
//   return window.innerHeight
// }
//
// function debounce(func, wait, immediate) {
// 	var timeout;
// 	return function() {
// 		var context = this, args = arguments;
// 		var later = function() {
// 			timeout = null;
// 			if (!immediate) func.apply(context, args);
// 		};
// 		var callNow = immediate && !timeout;
// 		clearTimeout(timeout);
// 		timeout = setTimeout(later, wait);
// 		if (callNow) func.apply(context, args);
// 	};
// };

const scaleNumber = (num, in_min, in_max, out_min, out_max) => {
  return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min
}

const capNumber = (num, min, max) => {
  if(num < min) return min
  if(num > max) return max
  return num
}

function ifExistMerge(arrays) {

  const filter = arrays.filter(o => o && Array.isArray(o))
  return !filter || filter.length === 0 ? null : filter.reduce((a, b) => [...a, ...b])
}

class ImageFixedChapter extends Component {
  constructor(props) {
    super(props)
    this.state = {scrollPosition:0}
    this.myRef = React.createRef()
  }

  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScroll);
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.id !== nextProps.id) return true
    if(this.props.type !== nextProps.type) return true
    if(this.props.isActive !== nextProps.isActive) return true
    if(this.props.nearViewport !== nextProps.nearViewport) return true
    if(this.props.blocks.length !== nextProps.blocks.length) return true
    // if(this.state.scrollPosition !== nextProps.scrollPosition) return true

    return false
  }

  // handleScroll = debounce(() => {
  //   if(this.props.isActive) {
  //     this.setState({scrollPosition: getScroll() + getWindowHeight()/2})
  //     console.log('Scrolling ImageFixedChapter')
  //   }
  //
  // }, 5)

  getOpacity = (innerOffset, innerHeight, outerHeight, maxOpacity) => {
    const offset = this.myRef && this.myRef.current ? this.myRef.current.offsetTop : 9999999999
    const {scrollPosition} = this.state

    const componentCenterPosition = offset + innerOffset
    const distanceFromCenter = Math.abs(componentCenterPosition - scrollPosition)
    const opacity = capNumber(scaleNumber(distanceFromCenter, innerHeight, outerHeight, maxOpacity, 0), 0, maxOpacity)
    return opacity
  }

  render() {

    const {blocksByType} = this.props

    const {h1, p, image, video, spotify, quote, vimeo, ...rest} = blocksByType

    const textBlocks = ifExistMerge([h1, p, quote])
    const imageBlocks = ifExistMerge([image, spotify])
    const videoBlocks = ifExistMerge([video, vimeo])
    const restBlocks = rest ? ifExistMerge(Object.values(rest)) : []


    // console.log('Rendering ImageFixedChapter', this.props.id)

    // const maxOpacity = .9

    // const componentHeight = this.myRef && this.myRef.current ? this.myRef.current.clientHeight : 0
    // const innerOffset = componentHeight/2
    // const opacityBackground = this.getOpacity(innerOffset, innerOffset, innerOffset+innerOffset/4, 1)
    // console.log('Should render', this.props.chapterIndex, this.props.loaded)
    return(

      <div ref={this.myRef} style={{color:'#fff', width:'100%', minHeight:'70vh', opacity: this.props.isActive ? 1 : 0}}>

          {
            textBlocks &&
            <Section {...this.props} >
              <Split narrow style={{ zIndex:30, opacity: this.props.isActive ? 1 : 1 }}>
                {
                  textBlocks.map((block, index) => <Block nearViewport={this.props.nearViewport} key={index} {...block}/>)
                }
              </Split>
            </Section>
          }

          {
            imageBlocks &&
            imageBlocks.map((block, index) => <Block {...block} key={index} type={'imageFixed'} nearViewport={this.props.nearViewport} opacity={this.props.isActive ? .8 : 0}/>)
          }

          {
            // (videoBlocks && this.props.isActive) &&
            // <Block value={videoBlocks} isActive={this.props.isActive} type={'videoFixed'} loaded={this.props.loaded} opacity={this.props.isActive ? .8 : 0}/>
          }

          {
            (videoBlocks && this.props.nearViewport) &&
            videoBlocks.map((block, index) => <Block {...block} key={index} type={'videoFixed'} nearViewport={this.props.nearViewport} opacity={this.props.isActive ? .8 : 0}/>)
          }



      </div>
    )
  }
}

export default ImageFixedChapter
