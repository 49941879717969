import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux'
import {
  withHandlers,
  withContext,
  getContext,
} from 'recompose'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'
import { setActiveChapter } from '../redux/actions';
import {Helmet} from "react-helmet";
import styled from 'styled-components'
import queryString from 'query-string'

import ChapterController from './Chapter/ChapterController'
import ProgressBar from './ProgressBar'
import Uploader from './Uploader'
import Logo from './Logo'
import Header from './Header'
import StoryEditableTitle from './StoryEditableTitle'

import page from './data'


class Story extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuMode:'dark'
    }
  }

  componentWillUnmount() {
    this.props.clearStorage()
  }

  changeMenuMode = (mode) => {
    this.setState({menuMode:mode})
  }


  canEdit = () => {
    const parsed = queryString.parse(this.props.location.search, {arrayFormat: 'comma'})
    return parsed.password === 'sesame'
  }


  shouldComponentUpdate(nextProps) {
    if(this.props.story !== nextProps.story || this.props.style !== nextProps.style  ) return true
    return false
  }

  render() {

    var {story, style = styles.page} = this.props


    return(
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{this.props.title}</title>
        {this.props.story.ico && <link rel="shortcut icon" href={this.props.story.ico}/>}
        {this.props.story.metaimage && <meta property="og:image" content={this.props.story.metaimage}/>}
        <meta property="og:title" content={this.props.title}/>
        <meta name="title" content={'Story'}/>

      </Helmet>

      <Header>
        <Logo link={this.props.uid ? '/s' : 'https://togather.ai'} src={this.props.story.ico}/>
        <StoryEditableTitle/>
      </Header>

      <WithUploader canEdit={this.props.canEdit}>
        <div className={css(styles.page)}
                    style={style}>

          <ProgressBar color={style.accentColor}/>
          <ChapterController storyStyle={this.props.story.style} storyId={this.props.storyId}/>

        </div>
      </WithUploader>
      </>


    )
  }
}

const WithUploader = ({children, ...props}) =>
  props.canEdit
    ?
      <Uploader>{children}</Uploader>
    : <>{children}</>






const ChapterList = ({})

const Menu = ({children}) =>
  <div className={css(styles.menu)}>
    {
      children.map((menuItem, i) => <div key={i} className={css(styles.menuItem)}>{menuItem}</div>)
    }
  </div>



const styles = StyleSheet.create({

  menu: {
    background:'white'
  },
  menuItem: {
    textAlign:'center',
    fontSize:18,
    lineHeight:1.4,
    color:'#000',
    cursor:'cursor',
    transition:'color .7s ease',
    ':hover': {
      color:'#aaa'
    }
  },

  hidden: {
    opacity:0,
    marginTop:150,
    transition:'opacity .7s ease, margin .5s ease'
  },

  page: {
    zIndex:0,
    margin:0,
    position:'relative',
    fontFamily:'akkurat',
    fontWeight:600,
    width:'100%',
    height:'100%',
    minHeight:'100vh',
    flexDirection:'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 800px)': {
      flexDirection:'column'
    }
  },


});



const mapStateToProps = (state, ownProps) => {
  const chapters = state.firestore.data.chapters
                 ? state.firestore.data.chapters
                 : []
 const activeChapterId = state.nav.activeChapter
 const activeChapter = chapters[activeChapterId]

 const story = state.firestore.data.activeStory ? state.firestore.data.activeStory : []

 const storyStyle = story && story.style ? story.style : {}
 const activeChapterTypeStyle =  state.firestore.data.chapterTypes
                              && activeChapter && activeChapter.typeId
                              && state.firestore.data.chapterTypes[activeChapter.typeId]
                              && state.firestore.data.chapterTypes[activeChapter.typeId].style
                              ? state.firestore.data.chapterTypes[activeChapter.typeId].style
                              : {}


 const activeChapterStyle = activeChapter && activeChapter.style ? activeChapter.style : {}

 // Define style defaults
 var styleDefaults = {
   backgroundColor: '#fff',
   color:'#000',
 }

 // Merge and override styles
 var style = { ...styleDefaults, ...storyStyle, ...activeChapterTypeStyle, ...activeChapterStyle }

  return {
    style,
    story,
    title: story.title,
    author: story.author,
    client: story.client,
    storyId: ownProps.match.params.storyId,
    canEdit: story.writers && story.writers.includes(state.firebase.auth.email),
    uid: state.firebase.auth.uid
    // activeChapterId,
    // activeChapter: chapters[activeChapterId]
  }
}



const mapDispatchToProps = dispatch => {
  return {
    clearStorage: (id) => {
      dispatch({ type: actionTypes.CLEAR_DATA })
      dispatch(setActiveChapter(null))
    },
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [{
      collection: 'stories',
      doc: props.match.params.storyId,
      storeAs: 'activeStory'
    }]
  }),
)(Story)
