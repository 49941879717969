import React, {Component} from 'react'
import styled from 'styled-components'
import { StyleSheet, css } from 'aphrodite'

import {Row, Column, Section, Split, Heading, ColumnFill, Cover} from './Shared'
import Block from '../Block'


function ifExistMerge(arrays) {

  const filter = arrays.filter(o => o && Array.isArray(o))
  return !filter || filter.length === 0 ? null : filter.reduce((a, b) => [...a, ...b])
}

const DefaultChapter = props => {

  const {blocksByType, blocks} = props

  const {h1, p, image, video, spotify, vimeo, ...rest} = blocksByType

  const textBlocks = ifExistMerge([h1, p])
  const imageBlocks = ifExistMerge([image, video, spotify, vimeo])
  const restBlocks = rest ? ifExistMerge(Object.values(rest)) : []

  const blockCount = props.blocks.length
  const isSingleItem = !blocksByType.h1 && !blocksByType.p && !blocksByType.spotify && blocksByType.image && blocksByType.image.length === 1

  return(
    <Row narrow hasMinimumHeight reversed {...props}>
      { textBlocks &&
        <Column>
          {textBlocks.map((block, index) => <><Block narrow nearViewport={props.nearViewport} key={index} {...block}/><br/></>)}
        </Column>
      }
      { (imageBlocks && props.nearViewport) &&
        <Column>
          <Row>
           {imageBlocks.map((block, index) => <Block nearViewport={props.nearViewport} narrow={!isSingleItem} key={index} {...block}/>)}
          </Row>
        </Column>
      }
      { restBlocks &&
        <Column>
          {restBlocks.map((block, index) => <Block key={index} {...block} fileType={block.type} type={'openButton'}/>)}
        </Column>
      }

    </Row>
  )


}

export default DefaultChapter
