import React, { Component } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

function generateSequence() {
  const types = [
    '0% 0% 0% 0%',
    '100% 100% 100% 100%',
    '100% 0% 0% 0%',
    '0% 100% 0% 0%',
    '0% 0% 100% 0%',
    '0% 0% 0% 100%',
  ]
  const i1 = getRandomInt(types.length - 1)
  const seq = [
    types[i1],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[getRandomInt(types.length - 1)],
    types[i1],
  ]
  return seq
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max))
}

const Loader = (props) => (
  <motion.div
    style={{
      width: props.size * 2,
      height: props.size * 2,
      opacity: 0,
      display: 'flex',
      flexDirection: 'column',
    }}
    animate={{
      height: props.size,
      width: props.size,
      opacity: 1,
    }}
    transition={{
      duration: 0.5,
      ease: 'easeInOut',
    }}
  >
    <div style={{ display: 'flex', flex: '1 1 0' }}>
      <LoaderBlock background="#f05f55" />
      <LoaderBlock />
      <LoaderBlock />
    </div>
    <div style={{ display: 'flex', flex: '1 1 0' }}>
      <EmptyBlock />
      <LoaderBlock background="#042335" />
      <EmptyBlock />
    </div>
    <div style={{ display: 'flex', flex: '1 1 0' }}>
      <EmptyBlock />
      <LoaderBlock background="#042335" />
      <EmptyBlock />
    </div>
  </motion.div>
)

const EmptyBlock = () => <div style={{ flex: '1 1 0' }} />

class LoaderBlock extends Component {
  constructor(props) {
    super(props)
    this.state = { sequence: generateSequence() }
  }
  render() {
    return (
      <motion.div
        style={{
          flex: '1 1 0',
          background: this.props.background ? this.props.background : '#5930E5',
        }}
        animate={{
          // background:['#5930E5', '#042335', '#f05f55', '#f05f55', '#5930E5'],
          borderRadius: this.state.sequence,
        }}
        transition={{
          duration: 4,
          ease: 'easeInOut',
          // times: [0, 0.3, 0.55, 0.75, .9, 1],
          loop: Infinity,
          // repeatDelay: 1
        }}
      />
    )
  }
}

Loader.propTypes = {
  size: PropTypes.number,
}

Loader.defaultProps = {
  size: 80,
}

const Loading = (props) => {
  if (props.fullscreen)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Loader size={props.size} />
          <br />
          {props.text}
        </div>
      </div>
    )
  else
    return (
      <div style={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>
        <Loader size={props.size} />
        {props.text}
      </div>
    )
}

export default Loading
