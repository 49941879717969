import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'


const ChapterList = ({children, ...props}) =>
  <div style={{position:'fixed', zIndex: 10, left:16, top: 32}}>
    {
      props.chapters.map(chapter => {
        return(
          <div>{chapter.id}</div>
        )
      })
    }
  </div>



const mapStateToProps = (state, ownProps) => {

  return {
    chapters:
      state.firestore.ordered.chapters
        ? state.firestore.ordered.chapters
        : [],
    activeChapterId: state.nav.activeChapter
  }
}

const mapDispatchToProps = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ChapterList)
