import React, {Component} from 'react'
import styled from 'styled-components'
import { LazyLoad } from './Common'

const Cover = styled.div(props => ({
  backgroundImage: `url(${props.image})`,
  backgroundPosition:'center',
  backgroundSize:'cover',
  height:'100vh',
  width:'100%',
  alignItems:'center',
  justifyContent:'center',
  flexDirection:'column',
  padding:0,
  ...props.style
}))


// Do we need to use these??
// onMouseEnter={props.onMouseEnter}
// onMouseLeave={props.onMouseLeave}


const FixedImage = ({children, ...props}) => {
  return(
    <LazyLoad hide={!props.isActive}>
      <Cover
        image={props.value}
        {...props}
        style={{
          zIndex:-30,
          left:0,
          top:0,
          display:props.isActive ? 'flex' : 'none',
          opacity:props.opacity,
          ...props.style,
          position:props.isActive ? 'fixed' : 'absolute',
        }}>
          {children}
        </Cover>
      </LazyLoad>

  )
}


export { FixedImage }
