import React, { useState } from 'react'
import { firestoreConnect, withFirestore } from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { deleteStory, copyStory } from '../../helpers/firestoreActions'
import Button from '../Button'
import StoryList from './StoryList'
import Logo from '../Logo'
import Header from '../Header'
import Confirm from '../Confirm'

import { Plus } from '@styled-icons/boxicons-regular/Plus'

document.body.style.backgroundColor = '#111'

const Main = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#111',
  color: '#fff',
  paddingTop: 100,
  paddingBottom: 100,
}))

const Heading = styled.div((props) => ({
  fontSize: 50,
  fontWeight: 800,
  margin: 16,
}))

function Dashboard({ firestore: { add }, ...props }) {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [storyToDeleteId, setStoryToDeleteId] = useState(null)

  function openStory(id) {
    props.history.push('/s/' + id)
  }

  function confirmDeleteStory() {
    deleteStory(storyToDeleteId)
      .then(() => {
        setStoryToDeleteId(null)
        setDeleteConfirmOpen(false)
      })
      .catch((e) => console.log('Error deleting story', e))
  }

  function handleClickDelete(event, storyId) {
    event.stopPropagation()
    setStoryToDeleteId(storyId)
    setDeleteConfirmOpen(true)
  }

  function handleClickDuplicate(event, storyId) {
    event.stopPropagation()
    copyStory(storyId)
  }

  function handleCancelDelete() {
    setStoryToDeleteId(null)
    setDeleteConfirmOpen(false)
  }

  function createStory() {
    const story = {
      title: 'Untitled',
      createdBy: props.uid,
      writers: [props.email],
      defaultChapterTypeId: 'FDsXOeGewMqIYqWH6e0S',
      availableChapterTypes: [
        'FDsXOeGewMqIYqWH6e0S',
        'zA9twpIHfM8M7LHSDs37',
        'TGKWpCXvgU7uY2Rzy6Eg',
        'L5FMfIQ64EvUlSV56CAe',
      ],
      createdAt: props.firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: props.firebase.firestore.FieldValue.serverTimestamp(),
    }
    add('stories', story).then((storyRef) => openStory(storyRef.id))
  }

  return (
    <Main>
      <Header>
        <Logo text={'Story'} />
      </Header>

      <Heading>Your stories</Heading>
      <Button onClick={createStory}>
        <Plus style={{ height: 32, color: '#000' }} />
      </Button>
      {props.stories.length > 0 && (
        <StoryList
          stories={props.stories}
          handleClickOpen={openStory}
          handleClickDuplicate={handleClickDuplicate}
          handleClickDelete={handleClickDelete}
        />
      )}
      {deleteConfirmOpen && (
        <Confirm
          text={'Are you sure you want to permanently delete this story?'}
          handleClickYes={confirmDeleteStory}
          handleClickNo={handleCancelDelete}
          handleClickOutside={handleCancelDelete}
        />
      )}
    </Main>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    stories: state.firestore.ordered.stories
      ? state.firestore.ordered.stories
      : [],
    email: state.firebase.auth.email,
    uid: state.firebase.auth.uid,
  }
}

const mapDispatchToProps = {}

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'stories',
        where: ['writers', 'array-contains', props.email],
        doc: props.match.params.storyId,
        orderBy: ['createdAt', 'desc'],
      },
    ]
  })
)(Dashboard)
