import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
// import 'firebase/database'
import 'firebase/storage'

var firebaseConfig = {
  apiKey: "AIzaSyDWUcPb4urQLhVYI1EKFdmC_7R1entZ_b8",
  authDomain: "chptrs.firebaseapp.com",
  databaseURL: "https://chptrs.firebaseio.com",
  projectId: "chptrs",
  storageBucket: "chptrs.appspot.com",
  messagingSenderId: "170413161208",
  appId: "1:170413161208:web:eca8b54cfd0f83e1"
}

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const firebaseAuth = firebase.auth

export {
  firebase,
  firebaseAuth,
  db
}
