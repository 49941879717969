import React from 'react'
import { motion } from 'framer-motion'
import MenuItem from './MenuItem'

const variants = {
  open: {
    transition: { staggerChildren: 0.03, delayChildren: 0.1 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const Menu = (props) => (
  <motion.ul style={{ textAlign: 'right' }} variants={variants}>
    {props.actions.map((action, i) => (
      <MenuItem action={action} key={i} />
    ))}
  </motion.ul>
)

export default Menu
