import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import addIcon from '../../graphics/close.svg'

const Sensor = styled.div(({ placement }) => ({
  ...placement,
  position: 'absolute',
  zIndex: 50,
  cursor: 'default',
}))

const Button = ({ children, placement, vertical, hoverStyle, ...props }) => (
  <motion.div
    style={{
      // color: mouseOverButton ? '#000' : '#aaa',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: placement === 'right' ? -60 : placement === 'left' ? 60 : 0,
      top: 0,
      cursor: 'pointer',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      zIndex: 51,
      backgroundColor: vertical ? 'transparent' : '#eee',
    }}
    transition={{
      duration: 0.05,
    }}
    whileTap={{
      backgroundColor: '#5930E5',
    }}
    whileHover={{
      ...hoverStyle,
    }}
    {...props}
  >
    {children}
  </motion.div>
)

const ChapterDivider = ({
  chapterRef,
  onClick,
  vertical,
  placement,
  children,
}) => {
  const opened = vertical ? { width: 0 } : { height: 1 }

  const closed = vertical
    ? { backgroundColor: 'transparent' }
    : { backgroundColor: '#eee' }

  const sensorPlacement = vertical
    ? {
        left: -100,
        top: 0,
        width: 200,
        height: '100%',
      }
    : {
        top: -75,
        left: 0,
        height: 150,
        width: '100%',
      }

  return (
    <motion.div
      style={{
        position: 'relative',
        zIndex: 50,
        color: '#000',
        opacity: 0,
        ...closed,
      }}
      transition={{
        duration: 0.2,
      }}
      whileHover={{
        opacity: 1,
        ...opened,
      }}
    >
      <Button
        onClick={onClick}
        vertical={vertical}
        placement={placement}
        hoverStyle={{ backgroundColor: vertical ? 'transparent' : '#5930E5' }}
      >
        {children}
      </Button>

      <Sensor placement={sensorPlacement} />
    </motion.div>
  )
}

export { ChapterDivider }
