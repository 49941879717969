import React, {Component} from 'react'
import styled from 'styled-components'
import { LazyLoad } from './Common'


const StyledH2 = styled.div(props => ({
  fontFamily:'ITC-Clearface',
  textAlign:'center',
  fontSize:60,
  fontWeight:500,
  margin:32,
  lineHeight:1.2,
  '@media (max-width: 1300px)': {
    fontSize:45,
    textAlign:'center',

  },
  '@media (max-width: 768px)': {
    fontSize:30,
    textAlign:'center',
    lineHeight:1.1,
  }
}))


const Heading = ({children, ...props}) => {

  var words = props.value.split(' ')
  var lastWord = words.pop()
  words = words.join(' ')

  return(
    <StyledH2
      id={props.blockId}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      {...props}>
      <LazyLoad hide={!props.nearViewport} {...props}>
        {words}&nbsp;{lastWord}
        {children}
      </LazyLoad>
    </StyledH2>

  )

}

export { Heading }
