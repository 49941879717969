import React from 'react'
import styled from 'styled-components'

const ButtonElement = styled.div((props) => ({
  margin: 4,
  display: 'inline-block',
}))

const Button = styled.div(({ text, icon, size, ...props }) => ({
  minHeight: size ? size : 42,
  minWidth: size ? size : 42,
  width: size,
  height: size,
  padding: icon && text ? '0 8px' : 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  scale: 1,
  opacity: 1,
  color: '#fff',
  backgroundColor: props.color ? props.color : '#ddd',
  borderRadius: 48,
  cursor: 'pointer',
  ...props.style,
  ':hover': {
    scale: 1.1,
    backgroundColor: props.hoverColor ? props.hoverColor : '#5930E5',
  },
  ':active': {
    scale: 0.95,
    backgroundColor: props.activeColor ? props.activeColor : '#5930E5',
  },
}))

const ButtonWithElements = ({ children, ...props }) => (
  <Button {...props}>
    {props.icon && <ButtonElement>{props.icon}</ButtonElement>}
    {props.text && <ButtonElement>{props.text}</ButtonElement>}
    {children && <ButtonElement>{children}</ButtonElement>}
  </Button>
)

export default ButtonWithElements
