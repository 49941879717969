import React, {useState, useEffect} from 'react'
import { motion, AnimatePresence} from "framer-motion"
import styled from 'styled-components'

import {Section, Split} from './Shared'

const Main = styled.div(props => ({
  height:'100vh',
  width:'100%',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  overflow:'hidden'
}))

const Item = styled.div(props => ({
  position:'absolute',
  fontSize:20,
  fontWeight:800,
  margin:4,
  left: props.left && 0,
  right: props.right && 0
}))

const Column = styled.div(props => ({
  position:'relative',
  display:'inline-block',
  width:320,
  height:100
}))

const all = ['chapter title', 'paragraph', 'Spotify link', 'Vimeo link', '.gif link', '.jpg link']

function Suggestion() {

  var [activeSuggestion, setActiveSuggestion] = useState(all[0])

  useEffect(() => {
    var i = 0
    const interval = setInterval(() => {
      i++
      setActiveSuggestion(all[i % all.length])
    }, 750);
    return () => clearInterval(interval);
  }, []);

  return (
      <AnimatePresence>
        <motion.div
          key={activeSuggestion}
          initial={{ opacity: 0, y:100 }}
          animate={{ opacity: 1, y:0, }}
          transition={{
            y: {
              duration:0.25, ease:'easeOut'
            },
            opacity: {
              duration:0.25
            }
          }}
          exit={{ opacity: 0, y:-100 }}
        >
        <Item left>
          {activeSuggestion}
        </Item>
        </motion.div>
      </AnimatePresence>
  )
}

function Empty(props) {
  return(
    <Main>
      { props.canEdit &&
        <>
        <Column>
          <Item>To add content:</Item>
        </Column>
        <Column>
          <Item right>Copy and paste a </Item>
        </Column>
        <Column>
          <Suggestion/>
        </Column>
        </>
      }

    </Main>
  )
}

export default Empty
