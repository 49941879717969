import React, { useState, useRef, useEffect, Component } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Color from 'color'
import Emoji from 'react-emoji-render'
import ReactPlayer from 'react-player'

import {
  Page,
  Block,
  Heading,
  Description,
  Highlight,
  VisualContainer,
  VisualBlock,
  Button,
  Center,
} from './LandingStyles'
import { ArrowRight } from '@styled-icons/feather/ArrowRight'
import macbook from '../../video/Macbook_20200903.mp4'

const colors = {
  accentColor2: '#4109e8',
  accentColor3: '#4109e8',
  accentColor4: '#4109e8',
}

const Video = ({ src }) => {
  const playerRef = useRef(null)
  const containerRef = useRef(null)
  const [containerSize, setContainerSize] = useState({})
  const [videoSize, setVideoSize] = useState({})

  // Update player size so it covers the space in the container
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (containerRef.current) {
        setContainerSize({
          height: containerRef.current.clientHeight,
          width: containerRef.current.clientWidth,
        })
      }
    })
  }, [])

  const updateSize = () => {
    if (!videoSize.width && containerRef.current) {
      const { videoHeight, videoWidth } = playerRef.current.player.player.player
        ? playerRef.current.player.player.player
        : {}
      setVideoSize({ width: videoWidth, height: videoHeight })
      setContainerSize({
        height: containerRef.current.clientHeight,
        width: containerRef.current.clientWidth,
      })
    }
  }

  // Ratios
  const r1 = containerSize.width / containerSize.height
  const r2 = videoSize.width / videoSize.height

  return (
    <div
      ref={containerRef}
      style={{
        borderRadius: 16,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <ReactPlayer
        ref={playerRef}
        onReady={updateSize}
        onError={(e) => console.log('Playback error', e)}
        style={{
          position: 'absolute',
          width: r1 < r2 ? 'auto' : containerSize.width,
          height: r1 > r2 ? 'auto' : containerSize.height,
        }}
        width={r1 < r2 ? 'auto' : containerSize.width}
        height={r1 > r2 ? 'auto' : containerSize.height}
        url={src}
        playing
        playsinline
        loop
        muted
        config={{
          file: {
            attributes: {
              autoPlay: true,
              muted: true,
            },
          },
        }}
      />
    </div>
  )
}

const Placeholder = ({ direction = 'to top right', rotate = 30, ...props }) => {
  const baseColor = Color(props.color)
  const secondaryColor = baseColor.rotate(rotate).lighten(0.3).hex()

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundImage: `linear-gradient(${direction}, ${baseColor}, ${secondaryColor})`,
      }}
    >
      <Center>
        <Emoji style={{ fontSize: 70 }} text={':bulb:'} />
      </Center>
    </div>
  )
}

const TryButton = withRouter((props) => {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={{ pathname: '/login', search: props.location.search }}
    >
      <Button>
        I like magic
        <div style={{ width: 30, display: 'inline-block', marginLeft: 8 }}>
          <ArrowRight />
        </div>
      </Button>
    </Link>
  )
})

function Landing(props) {
  return (
    <>
      <Page>
        <Block>
          <Center>
            <Heading>Magic treatments 🪄</Heading>
            <br />
            <br />
            <TryButton />
          </Center>
        </Block>
      </Page>
    </>
  )
}

export default compose(withRouter)(Landing)
