import {db, firebase} from '../config/constants'

function deleteBlock(blockId) {
  if(!blockId || blockId.length === 0) return

  const blockRef = db.collection('blocks').doc(blockId)
  return blockRef.get()
    .then(doc => {

      // If it's an uploaded file, we need to remove the file in storage
      if(doc.data().original) {

        const { path } = doc.data().original
        const storageRef = firebase.storage().ref()
        var fileRef = storageRef.child(path)

        // Delete the file
        return fileRef.delete()
      }
      else return Promise.resolve()
    })
    .then(() => {

      // Then we can delete the db item
      return db.collection('blocks').doc(blockId).delete()
    })
}

function deleteChapter(chapterId) {

  // Delete blocks first
  const chapterRef = db.collection('chapters').doc(chapterId)
  const blocksRef = db.collection('blocks').where('chapterId', '==', chapterId)

  return blocksRef.get()
    .then(snap => {
      // Delete all blocks and block uploads
      var promises = []
      snap.forEach(block => promises.push(deleteBlock(block.id)))
      return Promise.all(promises)
    })
    .then(() => {
      // All blocks were deleted, proceed to delete chapter
      return chapterRef.delete()
    })
  }

function deleteStory(storyId) {

  // Delete blocks first
  const storyRef = db.collection('stories').doc(storyId)
  const chaptersRef = db.collection('chapters').where('storyId', '==', storyId)

  return chaptersRef.get()
    .then(snap => {
      // Delete all chapters and chapter blocks
      var promises = []
      snap.forEach(chapter => promises.push(deleteChapter(chapter.id)))
      return Promise.all(promises)
    })
    .then(() => {
      // All blocks were deleted, proceed to delete chapter
      return storyRef.delete()
    })
  }


async function copyChapter(chapterId, newProps) {

  const oldChapterRef = db.collection('chapters').doc(chapterId)
  const newChapterRef = db.collection('chapters').doc()

  // First, get the chapter to copy
  const chapterDoc = await oldChapterRef.get()

  // Create a copy with new props (ie storyId)
  const oldData = chapterDoc.data()
  const copy = {...oldData, ...newProps}

  // Set the chapter
  newChapterRef.set(copy)

  const blocks = await db.collection('blocks').where('chapterId', '==', chapterId).get()

  // Copy all blocks
  return Promise.all(blocks.docs.map(block => {
    const data = block.data()
    const newBlockRef = db.collection('blocks').doc()

    let promises = []
    let copy = {...data, chapterId: newChapterRef.id}

    /// Todo: Should duplicate storage files!!
    if(data.original) {
      const { path, bucketName } = data.original
      const ext = path.split('.').pop()
      const newPath = 'originals/' + newBlockRef.id + '.' + ext

      copy.original = {
        path: newPath,
        bucketName
      }

      const copyStoragePromise = copyStorage(path, newPath)
      promises.push(copyStoragePromise)
    }


    promises.push(newBlockRef.set(copy))

    return Promise.all(promises)
  }))

}


async function copyStorage(path, newPath) {

  const storageRef = firebase.storage().ref()
  const fileRef = storageRef.child(path)

  // Delete the file
  const downloadUrl = await fileRef.getDownloadURL()
  const file = await makeRequest("GET", downloadUrl);

  const newFileRef = storageRef.child(newPath)
  return newFileRef.put(file)

}

function makeRequest(method, url) {
    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.send();
    });
}


function copyStory(storyId, newProps) {

  const oldStoryRef = db.collection('stories').doc(storyId)
  const newStoryRef = db.collection('stories').doc()

  const chaptersRef = db.collection('chapters').where('storyId', '==', storyId)

  return oldStoryRef.get()
    .then(storyDoc => {
      const oldData = storyDoc.data()
      const copy = {
        ...oldData,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        title: oldData.title + ' copy',
        ...newProps
      }
      return newStoryRef.set(copy)
    })
    .then(() => {
      return chaptersRef.get()
    })
    .then(snap => {
      // Copy all chapters
      var promises = []
      snap.forEach(chapter => promises.push(copyChapter(chapter.id, { storyId:newStoryRef.id })))
      return Promise.all(promises)
    })
  }




export { deleteBlock, deleteChapter, deleteStory, copyStory }
