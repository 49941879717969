import React, {Component} from 'react'
import styled from 'styled-components'
import { StyleSheet, css } from 'aphrodite'

import {Row, Column, Section, Split, Heading, ColumnFill, Cover} from './Shared'
import Block from '../Block'

function ifExistMerge(arrays) {

  const filter = arrays.filter(o => o && Array.isArray(o))
  return !filter || filter.length === 0 ? null : filter.reduce((a, b) => [...a, ...b])
}


const CoverChapter = props => {

  const {blocksByType} = props
  const {h1, p, image, video, vimeo, ...rest} = blocksByType

  const textBlocks = ifExistMerge([h1, p])
  const imageBlocks = ifExistMerge([image])
  const videoBlocks = ifExistMerge([video, vimeo])
  const restBlocks = rest ? ifExistMerge(Object.values(rest)) : []

  const classDefaultStyle = {
    color: blocksByType.image && blocksByType.image[0] ? '#fff' : '#000'
  }

  const styleOverride = {...props.chapterType.style, ...props.style, color: props.storyStyle && props.storyStyle.accentColor}

  return (
    <Cover style={{minHeight:'100vh', ...classDefaultStyle, ...styleOverride, position:'relative'}} image={blocksByType.image ? blocksByType.image[0].value : ''}>
      <div style={{position:'absolute', width:'100%', height:'100vh', display:'flex', flexDirection:'column'}} horisontal={props.horisontal}>
        {imageBlocks && imageBlocks.map((block, index) => <Block key={index} {...block} type={'coverImage'}/>)}
        {(videoBlocks && props.nearViewport) && videoBlocks.map((block, index) => <Block key={index} nearViewport={props.nearViewport} {...block} type={'videoFill'}/>)}
      </div>
      <Row>
      <Column>
      {blocksByType.p && blocksByType.p[0] &&
        blocksByType.p.slice(0,Math.ceil(blocksByType.p.length/2)).map((block, index) => <Block {...block} key={index} style={{textAlign:'center'}} />)
      }
      {blocksByType.h1 &&
        blocksByType.h1.map((block, index) => <Block {...block} key={index} type={'h2'}/>)
      }
      {blocksByType.p && blocksByType.p[1] &&
        blocksByType.p.slice(Math.ceil(blocksByType.p.length/2)).map((block, index) => <Block {...block} key={index} style={{textAlign:'center'}}/>)
      }
      </Column>
      </Row>
    </Cover>)
}

export default CoverChapter
