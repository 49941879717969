import React from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion"

function SwipingOverlay({swipingTo}) {
  return(
    <Overlay>
      <Animated swipingDirection={swipingTo.dir}>
        <Heading>{swipingTo.chapterType.name}</Heading>
        <Detail>{swipingTo.chapterType.description}</Detail>
      </Animated>
    </Overlay>
  )
}

const Heading = styled.div(props => ({
  fontSize:40,
}))

const Detail = styled.div(props => ({
  fontSize:20,
}))


const Animated = ({children, swipingDirection}) => <motion.div
    key={swipingDirection}
    style={{
      height:'100%',
      fontFamily:'ITC-Clearface',
      color:'#fff',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      flexDirection:'column',
      opacity:1,
      // x: 30 * (swipingDirection === 'Left' ? 1 : -1)
    }}
    // animate={{
    //   opacity:1,
    //   x:0,
    // }}
  >{children}</motion.div>

const Overlay = ({swipingDirection, children}) => <motion.div
    key={children}
    style={{
      position:'fixed',
      zIndex:200,
      width:'100%',
      height:'100%',
    }}
    animate={{
      backgroundColor:['#B57E62', '#A06344', '#9B723B', '#602E22', '#D6B79D'][Math.floor(Math.random()*5)]
    }}>
    {children}
    </motion.div>

export default SwipingOverlay
